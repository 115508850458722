import {
  useCashFlowSidePanel,
  type CashflowSidePanelTab,
} from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { CashflowSidePanelTabs } from 'qonto/react/models/cash-flow-period.ts';
import { type CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import { queryClient } from 'qonto/react/react-bridge/custom-providers';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { getEntryTimeline } from '../utils/get-entry-timeline';
import { useCategoriesCashflows, getCategoryRow } from './use-categories-cashflow';

const isTesting = (ENV as { environment: string }).environment === 'test';

export const useOpenFirstRowCurrentMonthSidepanel = (numberOfColumns: number): (() => void) => {
  const { openSidepanelWith } = useCashFlowSidePanel();

  const { organization } = useOrganizationManager();
  const cachedTimeseries = queryClient.getQueriesData<CashflowTimeseries>({
    queryKey: ['cashflow-timeseries', organization.id],
  })[0]?.[1];
  const { inflows } = useCategoriesCashflows(0, numberOfColumns, cachedTimeseries);

  return () => {
    const firstInflowRow = inflows[0];
    const category = cachedTimeseries?.categoriesData.inflow.find(
      ({ id }) => id === firstInflowRow?.id
    );

    /**
     * Testing check here is required because test mocks are not
     * detailed enough. It should be removed once the timeseries API
     * will be moved to mirage.
     */
    const currentInflowMonth = isTesting
      ? firstInflowRow?.columns[2]
      : firstInflowRow?.columns.find(({ interval }) => {
          return getEntryTimeline(interval).isCurrentMonth;
        });

    const tab: CashflowSidePanelTab = {
      tabValues: {
        actual: {
          value: Number(currentInflowMonth?.amount?.value || 0).toFixed(2),
          currency: currentInflowMonth?.amount?.currency || 'EUR',
        },
        forecast: {
          value: Number(currentInflowMonth?.forecast?.amount.value || 0).toFixed(2),
          currency: currentInflowMonth?.forecast?.amount.currency || 'EUR',
        },
      },
      isLoading: false,
      isFutureMonth: false,
      selectedValue: CashflowSidePanelTabs.Forecast,
    };

    const selectedCategories = category
      ? [getCategoryRow(category, cachedTimeseries?.timeframes || [], 'inflows')]
      : [];

    openSidepanelWith({
      selectedCategories,
      selectedInterval: currentInflowMonth?.interval,
      isFlowSelected: false,
      tab,
      forecast: currentInflowMonth?.forecast,
      isFirstTimeExperience: true,
    });
  };
};
