import type { ReactNode, HTMLAttributes } from 'react';
import { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropZone } from '@repo/design-system-kit';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMutation } from '@tanstack/react-query';
import XHRUpload from '@uppy/xhr-upload';
import { Uppy } from '@uppy/core';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import { apiBaseURL } from 'qonto/constants/hosts';
import { DownloadTemplateButton } from './download-button';
import styles from './import-forecast-view.strict-module.css';

interface StepProps extends HTMLAttributes<HTMLLIElement> {
  title: string;
  children: ReactNode;
}

function Step({ children, title, ...props }: StepProps): ReactNode {
  return (
    <li className={styles.step} {...props}>
      <h3 className={styles.stepTitle} data-testid="step-title">
        {title}
      </h3>
      <div className={styles.stepContent}>{children}</div>
    </li>
  );
}

interface ImportForecastViewProps {
  next: () => void;
}

class ResponseError extends Error {
  status: number;
  constructor(response: Response) {
    super(`Request failed with status ${response.status}`);
    this.status = response.status;
  }
}

export function ImportForecastView({ next }: ImportForecastViewProps): ReactNode {
  const { formatMessage } = useIntl();
  const [uploadError, setUploadError] = useState<string | undefined>(undefined);
  const networkManager = useEmberService('network-manager');
  const headers = useMemo(() => {
    const requestHeaders = { ...networkManager.requestHeaders } as Record<string, string>;
    delete requestHeaders['Content-Type'];
    return requestHeaders;
  }, [networkManager.requestHeaders]);

  // eslint-disable-next-line react/hook-use-state -- library author suggestion
  const [uppy] = useState<Uppy>(() =>
    new Uppy().use(XHRUpload, {
      endpoint: `${apiBaseURL}/v2/cash-flow/timeseries/forecasts/template/import`,
      fieldName: 'file',
      formData: true,
      withCredentials: true,
      allowedMetaFields: false,
      headers,
      onAfterResponse: async res => {
        switch (res.status) {
          case 204: {
            await cashFlowManager.updateFirstTimeExperienceState({
              forecast: {
                first_time_experience_state: FIRST_TIME_EXPERIENCE_STATES.FORECAST_SET,
              },
            });
            next();
            break;
          }
          case 422:
            setUploadError(
              formatMessage({
                id: 'cash-flow.first-time-experience.sidepanel.import.step-3.file-format-error',
              })
            );
            break;
          case 413:
            setUploadError(
              formatMessage({
                id: 'cash-flow.first-time-experience.sidepanel.import.step-3.file-size-error',
              })
            );
            break;
          default:
            sentry.captureException(res);
            setUploadError(
              formatMessage({
                id: 'cash-flow.first-time-experience.sidepanel.import.step-3.generic-error',
              })
            );
            break;
        }
      },
    })
  );
  const sentry = useEmberService('sentry');
  const cashFlowManager = useEmberService('cash-flow-manager');

  const { mutate: uploadFile, isPending } = useMutation<unknown, ResponseError, File[]>({
    mutationKey: ['import-forecast'],
    mutationFn: async files => {
      setUploadError(undefined);
      const file = files[0];

      if (!file) {
        return;
      }

      uppy.addFile(file);
      await uppy.upload();
    },
  });

  return (
    <ol className={styles.list} data-testid="import-forecast">
      <Step
        data-testid="step-1"
        title={formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.import.step-1.title',
        })}
      >
        <DownloadTemplateButton />
      </Step>
      <Step
        data-testid="step-2"
        title={formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.import.step-2.title',
        })}
      >
        <p className={styles.secondStep} data-testid="disclaimer">
          <FormattedMessage id="cash-flow.first-time-experience.sidepanel.import.step-2.description" />
        </p>
      </Step>
      <Step
        data-testid="step-3"
        title={formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.import.step-3.title',
        })}
      >
        <DropZone
          acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          className={cx(isPending && styles.dropzoneDisabled)}
          customErrorMessage={uploadError}
          data-testid="template-dropzone"
          isDisabled={isPending}
          onFilesSelect={uploadFile}
          placeholder={formatMessage({
            id: 'cash-flow.first-time-experience.sidepanel.import.step-3.placeholder',
          })}
          showFileList={false}
        />
      </Step>
    </ol>
  );
}
