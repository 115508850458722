import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { IconBacklinkOutlined } from '@repo/monochrome-icons';
import { Popover, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { Filter } from 'qonto/react/assets/icons/filter';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { TransactionInitiator } from 'qonto/react/models/transaction';
import { MembershipItem } from './membership-item';
import styles from './styles.strict-module.css';
import { MembershipAction } from './membership-action';

interface MemberCellPopoverProps {
  membership: TransactionInitiator;
}

export function MembershipCellPopover({ membership }: MemberCellPopoverProps): ReactNode {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { organization } = useOrganizationManager();
  const capitalize = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const team = membership.team.name ? capitalize(membership.team.name) : '-';

  const filterByMember = (): void => {
    void router.push(`/organizations/${organization.slug}/transactions?initiator=${membership.id}`);
  };

  const viewMemberProfile = (): void => {
    void router.push(`/organizations/${organization.slug}/members/active/${membership.id}`);
  };

  return (
    <Popover>
      <PopoverSection className={styles.section}>
        <MembershipItem data-testid="email-item" information="email" value={membership.email} />
        <MembershipItem
          data-testid="role-item"
          information="role"
          value={capitalize(membership.role)}
        />
        <MembershipItem data-testid="team-item" information="team" value={team} />
      </PopoverSection>
      <PopoverSection className={styles.section}>
        <MembershipAction
          data-testid="filter-action"
          icon={<Filter />}
          onPress={filterByMember}
          text={formatMessage({ id: 'transactions.modular_table.member.filter.cta' })}
        />
        <MembershipAction
          data-testid="view-profile-action"
          icon={<IconBacklinkOutlined />}
          onPress={viewMemberProfile}
          text={formatMessage({ id: 'transactions.modular_table.member.view.cta' })}
        />
      </PopoverSection>
    </Popover>
  );
}
