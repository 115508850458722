import type { ReactNode } from 'react';
import { useState, useMemo } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMutation } from '@tanstack/react-query';
import { useSegment } from '@repo/poly-hooks/segment';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import styles from './intro-screen.strict-module.css';

interface IntroScreenProps {
  nextStep: () => void;
}

export function IntroScreen({ nextStep }: IntroScreenProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  const segment = useSegment();
  const cashFlowManager = useEmberService('cash-flow-manager');
  const [hasViewedIntro, setHasViewedIntro] = useState(true); // Replace `true` with `isTesting` when implementing the full animation

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const userState = await cashFlowManager.updateFirstTimeExperienceState({
        forecast: {
          first_time_experience_state: FIRST_TIME_EXPERIENCE_STATES.INTRO_COMPLETED,
        },
      });

      return userState;
    },
    onSuccess: () => {
      nextStep();
    },
  });

  const lottiePath = useMemo((): string => {
    const basePath = '/lotties/cash-flow/first-time-experience/';
    if (['de', 'fr'].includes(locale)) {
      return `${basePath}intro-placeholder-${locale}.json`;
    }
    return `${basePath}intro-placeholder-en.json`;
  }, [locale]);

  if (!hasViewedIntro) {
    return (
      <LottiePlayer
        className={styles.fullScreenAnimation}
        loop={false}
        onComplete={() => {
          setHasViewedIntro(true);
        }}
        path="/lotties/cash-flow/first-time-experience/intro-full-screen.json"
      />
    );
  }

  return (
    <div className={styles.introScreen} data-testid="intro-screen">
      <div className={styles.animatedHeader}>
        <h1 className={cx('title-1', 'mb-16')} data-testid="title">
          {formatMessage({ id: 'cash-flow.first-time-experience.intro.title' })}
        </h1>
        <p className={cx('body-2', 'mb-32')} data-testid="subtitle">
          {formatMessage({ id: 'cash-flow.first-time-experience.intro.subtitle' })}
        </p>
        <Button
          data-testid="cta"
          isLoading={isPending}
          onPress={() => {
            segment.track('cash-flow_fte_empty-state-cta_clicked');
            mutate();
          }}
        >
          {formatMessage({ id: 'cash-flow.first-time-experience.intro.cta' })}
        </Button>
      </div>
      <LottiePlayer
        className={styles.placeholderAnimation}
        loop={false}
        onDOMLoaded={() => {
          segment.track('cash-flow_fte_empty-state_viewed');
        }}
        path={lottiePath}
      />
    </div>
  );
}
