import { useRef, type ReactNode } from 'react';
import {
  LottiePlayer,
  type LottieRefCurrentProps,
  type LottiePlayerProps,
} from '@repo/design-system-kit';
import cx from 'clsx';
import styles from './marketing-card.strict-module.css';

interface MarketingCardProps {
  lottieProps: LottiePlayerProps;
  title: string;
  subtitle: string | ReactNode;
  type?: 'primary' | 'secondary';
}

export function MarketingCard({
  type = 'primary',
  title,
  subtitle,
  lottieProps,
}: MarketingCardProps): ReactNode {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  const handleCardMouseOver = (): void => {
    if (lottieRef.current) {
      lottieRef.current.play();
    }
  };

  const handleCardMouseLeave = (): void => {
    if (lottieRef.current) {
      lottieRef.current.stop();
    }
  };

  const textContent = (
    <div className={styles.textContent}>
      <h1 className="title-3 mb-8">{title}</h1>
      <p className={cx(styles.subtitle, 'body-2')}>{subtitle}</p>
    </div>
  );

  return (
    <div
      className={cx(styles.marketingCard, styles[type])}
      onBlur={handleCardMouseLeave}
      onFocus={handleCardMouseOver}
      onMouseEnter={handleCardMouseOver}
      onMouseLeave={handleCardMouseLeave}
    >
      {type === 'secondary' && textContent}

      <LottiePlayer
        autoplay={false}
        className={styles.lottie}
        loop={false}
        lottieRef={lottieRef}
        {...lottieProps}
      />

      {type === 'primary' && textContent}
    </div>
  );
}
