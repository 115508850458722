import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import type {
  SubscriptionChangeDataContext,
  TargetSubscription,
} from 'qonto/react/components/flows/subscription-change/data-context.type';

interface ConfirmUpgradeProps {
  context: SubscriptionChangeDataContext;
  header: ReactElement;
  onConfirm: () => void;
  subtitle: string;
  targetSubscription?: TargetSubscription;
  title: string;
}

export function ConfirmUpgrade({
  context,
  header,
  onConfirm,
  subtitle,
  targetSubscription,
  title,
}: ConfirmUpgradeProps): ReactElement {
  const { formatNumber, formatMessage, formatDate } = useIntl();

  const { estimatedPrice } = context;
  const price = formatNumber(Number(targetSubscription?.prorated_amount.value), {
    style: 'currency',
    currency: targetSubscription?.prorated_amount.currency,
  });

  return (
    <ConfirmLayout header={header} testId="data-test-free-confirm-trial-revamp">
      <>
        <ConfirmHeader pricePlan={{ price, subtitle, title }} />
        <ConfirmBillingSummary
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: formatDate(estimatedPrice.next_billing_date, {
                format: 'long',
              }),
            }
          )}
          confirmButtonTestId="data-test-free-trial-cta-revamp"
          confirmText={formatMessage({ id: 'subscription.change.addons.cta.free-trial' })}
          // TODO: update CTA & onConfirm & loading logic and products list
          isCtaDisabled={false}
          isLoading={false}
          onConfirm={onConfirm}
          price={formatNumber(Number(estimatedPrice.vat_included_amount.value), {
            style: 'currency',
            currency: estimatedPrice.vat_included_amount.currency,
          })}
          products={[]}
          testId="data-test-confirm-trial-total"
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
          totalEstimate={estimatedPrice}
        >
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx -- TODO: add disclaimers */}
          <div>disclaimer</div>
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}
