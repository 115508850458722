import { useIntl } from 'react-intl';
import type { ReactNode } from 'react';
import styles from './divider.strict-module.css';

export function Divider(): ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.divider}>
      {formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.divider' })}
    </div>
  );
}
