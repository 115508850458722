import { useSegment } from '@repo/poly-hooks/segment';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';

export const useScheduleSupplierInvoiceHandler = (
  upcomingTransaction: UpcomingTransaction | null
): (() => void) => {
  const segment = useSegment();
  const type = upcomingTransaction?.provider_object_type;

  return () => {
    segment.track('compass_action_clicked', {
      action: 'schedule_supplier_invoice',
      type,
    });
  };
};

export const useScheduleSupplierInvoice = (): {
  updateSupplierInvoice: (params: {
    type: string;
    supplierInvoiceId: string;
    newDate: string;
    counterpartyName: string;
  }) => void;
} => {
  const segment = useSegment();
  const router = useRouter();
  const { organization } = useOrganizationManager();

  const updateSupplierInvoice = ({
    type,
    supplierInvoiceId,
    newDate,
  }: {
    type: string;
    supplierInvoiceId: string;
    newDate: string;
    counterpartyName: string;
  }): void => {
    segment.track('compass_action_clicked', {
      action: 'schedule_supplier_invoice_submit',
      type,
    });

    const path = `/organizations/${organization.slug}/flows/sepa-transfer/invoice`;
    const queryParams = new URLSearchParams({
      supplierInvoiceId,
      origin: 'upcoming-transactions',
      scheduledDate: newDate,
    }).toString();
    void router.push(`${path}?${queryParams}`);
  };

  return { updateSupplierInvoice };
};
