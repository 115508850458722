import { type ReactNode, useMemo } from 'react';
import { Dialog } from 'react-aria-components';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { SidePanel } from 'qonto/react/shared/components/side-panel';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import { SidepanelHeader } from 'qonto/react/components/cash-flow/components/sidepanel/header';
import { SidePanelTabs } from 'qonto/react/components/cash-flow/components/sidepanel/tabs';
import styles from './cash-flow-sidepanel.strict-module.css';
import { FlashForecast } from './flash/flash-forecast';
import { ImportForecast } from './import-forecast';

export function CashFlowSidePanel(): ReactNode {
  const {
    isVisible,
    selectedCategories,
    isFlowSelected,
    selectedInterval,
    selectedFrequency,
    bankAccounts,
    periodNavigation,
    closeSidepanel,
    refreshChart,
    changeSidepanelTab,
    tab,
    forecast,
    mode,
  } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const frequencyFormat = useMemo(() => {
    if (selectedFrequency === CashflowPeriodRate.Yearly) {
      return 'YYYY';
    } else if (selectedFrequency === CashflowPeriodRate.Quarterly) {
      return `[Q]Q YYYY`;
    }
    return 'MMMM YYYY';
  }, [selectedFrequency]);

  return (
    <SidePanel
      className={styles['cash-flow-sidepanel']}
      data-testid="cash-flow-sidepanel"
      id="cash-flow-sidepanel"
      isVisible={isVisible}
      onClickOutside={closeSidepanel}
      options={{
        ignore: [
          '#cash-flow-category-popover svg > path',
          '#cash-flow-category-popover',
          '.flash-message',
          '#cashflow-balance-cell',
        ],
      }}
      width={468}
    >
      <Dialog>
        <div className={styles['cash-flow-sidepanel-wrapper']}>
          <SidepanelHeader
            frequencyFormat={frequencyFormat}
            isFlowSelected={isFlowSelected}
            onCloseSidepanel={closeSidepanel}
            periodNavigation={periodNavigation}
            selectedCategories={selectedCategories}
            selectedInterval={selectedInterval}
          />
          {mode === 'default' && (
            <SidePanelTabs
              bankAccounts={bankAccounts}
              changeTab={changeSidepanelTab}
              data={tab}
              forecast={forecast}
              isFlowSelected={isFlowSelected}
              refreshChart={refreshChart}
              selectedCategories={selectedCategories}
              selectedFrequency={selectedFrequency}
              selectedInterval={selectedInterval}
            />
          )}
          {mode === 'flashForecast' && <FlashForecast />}
          {mode === 'importForecast' && <ImportForecast />}
        </div>
      </Dialog>
    </SidePanel>
  );
}
