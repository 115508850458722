import type { ReactNode } from 'react';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconDownloadOutlined, IconFileAttachmentOutlined } from '@repo/monochrome-icons';
import { Button } from 'react-aria-components';
import { downloadHook } from 'qonto/react/hooks/use-download-with-headers';
import { apiBaseURL } from 'qonto/constants/hosts';
import styles from './download-button.strict-module.css';

export function DownloadTemplateButton(): ReactNode {
  const { locale } = useIntl();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { downloadFile } = downloadHook.useDownloadWithHeaders({
    href: `${apiBaseURL}/v2/cash-flow/timeseries/forecasts/template/download?lang=${locale}`,
    fallbackFilename: 'forecast_template.xlsx',
    customLink: linkRef.current,
  });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid -- Link used to append the downloaded file*/}
      <a ref={linkRef} style={{ visibility: 'hidden' }} tabIndex={-1} />
      <Button
        className={styles.downloadButton}
        data-testid="download-template-cta"
        onPress={downloadFile}
        type="button"
      >
        <span className={styles.buttonContent}>
          <span className={styles.fileIconWrapper}>
            <IconFileAttachmentOutlined />
          </span>
          <span className={styles.description}>
            <p className="body-1" data-testid="title">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.import.step-1.button" />
            </p>
            <p className={styles.fileSize} data-testid="file-size">
              <FormattedMessage id="cash-flow.first-time-experience.sidepanel.import.step-1.file-size" />
            </p>
          </span>
        </span>
        <IconDownloadOutlined />
      </Button>
    </>
  );
}
