import { useEffect, useState, type ReactNode } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnPinningState,
  type RowSelectionState,
} from '@tanstack/react-table';
import { useTableNav } from '@table-nav/react';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import cx from 'clsx';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import { CellProvider } from 'qonto/react/contexts/cell-context';
import type { Transaction } from 'qonto/react/graphql';
import { useTrackRender } from 'qonto/react/hooks/use-track-render';
import { SORTABLE_COLUMNS } from 'qonto/react/constants';
import type { DisplayColumn } from 'qonto/constants/table-view';
import { HeaderCell } from './components/header-cell';
import styles from './styles.strict-module.css';
import { BodyCell } from './components/body-cell';

interface TableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  updateColumn: (column: Partial<DisplayColumn>) => void;
  defaultColumnOrder: string[];
  handleSortBy?: (sortBy: string | undefined) => void;
  sortBy?: string;
}

export function DataTable<TData extends Transaction, TValue>({
  data,
  columns,
  updateColumn,
  handleSortBy,
  sortBy,
  defaultColumnOrder,
}: TableProps<TData, TValue>): ReactNode {
  useTrackRender({ eventName: 'transactions-modular-table-loaded' });
  const rightAlignedHeaders = ['amount', 'settledBalance', 'vat'];
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['bulk-select', 'transaction'],
  });
  const [columnOrder, setColumnOrder] = useState<string[]>(() => defaultColumnOrder);

  // handles selection at the table level
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // handles selection at the context level
  const { setSelection, selectedItemIds, shouldResetSelection, resetSelection } =
    bulkSelectionManager.useBulkSelection();

  useEffect(() => {
    setRowSelection(prev => {
      const initialRowSelection = selectedItemIds.reduce<RowSelectionState>((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      return { ...prev, ...initialRowSelection };
    });
  }, [selectedItemIds]);

  const table = useReactTable({
    data,
    columns,
    state: { columnPinning, columnOrder, rowSelection },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    enableColumnResizing: false,
    getRowId: row => row.id,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
  });

  useEffect(() => {
    setColumnOrder(defaultColumnOrder);
  }, [defaultColumnOrder]);

  useEffect(() => {
    setSelection(Object.keys(rowSelection));
  }, [rowSelection, setSelection]);

  useEffect(() => {
    if (shouldResetSelection) {
      table.resetRowSelection();
      resetSelection();
    }
  }, [shouldResetSelection, table, resetSelection]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as string);
      const newIndex = columnOrder.indexOf(over.id as string);

      updateColumn({
        id: active.id as string,
        position: newIndex,
      });

      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const { listeners } = useTableNav();
  const resizedColumn = table.getState().columnSizingInfo.isResizingColumn;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className={styles['data-table']} {...listeners}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                {headerGroup.headers.map((header, index) => (
                  <HeaderCell
                    className={cx(index === 0 && 'pinned-column')}
                    handleSortBy={handleSortBy}
                    header={header}
                    key={header.id}
                    resizedColumn={resizedColumn}
                    sortBy={sortBy}
                    updateColumn={updateColumn}
                    {...(rightAlignedHeaders.includes(header.id) && { align: 'right' })}
                    {...(SORTABLE_COLUMNS.includes(header.id) && { isSortable: true })}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        {table.getRowModel().rows.length ? (
          <tbody>
            {table.getRowModel().rows.map(row => {
              const isHighlighted =
                new URLSearchParams(window.location.search).get('highlight') === row.original.id;
              return (
                <CellProvider key={row.id} transaction={row.original}>
                  <tr
                    className={cx(styles['table-row'], isHighlighted && 'row-highlight')}
                    data-test-transaction-row={row.original.id}
                    data-testid="transaction-row"
                    id={`transaction-row-${row.original.id}`}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <SortableContext
                        items={columnOrder}
                        key={cell.id}
                        strategy={horizontalListSortingStrategy}
                      >
                        <BodyCell
                          cell={cell}
                          cellIndex={index}
                          className={cx(
                            styles['table-cell'],
                            styles['table-cell-hovered'],
                            index === 0 && 'pinned-column'
                          )}
                          key={cell.id}
                        />
                      </SortableContext>
                    ))}
                  </tr>
                </CellProvider>
              );
            })}
          </tbody>
        ) : null}
      </table>
    </DndContext>
  );
}
