import cx from 'clsx';
import type { ReactNode } from 'react';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './offer-card-loading.strict-module.css';

export function OfferCardLoading(): ReactNode {
  return (
    <div className={styles.container} data-test-financing-offer-card-loading>
      <div className={cx(styles.headerWrapper, 'mb-24')} data-test-loading-card-header>
        <div className={styles.header}>
          <SkeletonLoader.Block
            borderRadius="100px"
            data-test-loading-card-header-block
            height="40px"
            width="40px"
          />
          <SkeletonLoader.Line
            borderRadius="36px"
            className="ml-16"
            data-test-loading-card-header-line
            width="95px"
          />
        </div>

        <SkeletonLoader.Block
          borderRadius="4px"
          data-test-loading-card-header-block
          height="24px"
          width="79px"
        />
      </div>

      <SkeletonLoader.Header
        className="mb-12"
        data-test-loading-card-offer-description
        lines={1}
        smallLinesWidth="95px"
        width="456px"
      />

      <div className={styles.conditions} data-test-loading-card-conditions>
        {[...Array(3).keys()].map(index => (
          <div key={`condition-${index}`}>
            <SkeletonLoader.Header
              data-test-loading-card-conditions-block
              lines={1}
              smallLinesWidth="95px"
              width="55px"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
