import { useMemo, type ReactNode } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import type { BalanceData } from '../../models/balance';
import { generateLoadingBalanceData } from '../../utils/generate-loading-data';
import { TableCell } from '../shared/table-cell';
import { BalanceCell } from './balance-cell';
import { AttributeCell } from './attribute-cell';
import styles from './styles.strict-module.css';

export type BalanceTableType = 'start' | 'end';

interface TableData {
  attribute: string;
  [key: string]: string | number | BalanceData;
}

const buildTableSchema = (
  data: TableData[],
  isLoading: boolean,
  numberOfColumns: number,
  type: BalanceTableType,
  frequency?: CashflowPeriodRate
): {
  tableData: TableData[];
  columns: ColumnDef<TableData, string>[];
} => {
  const loadingData = generateLoadingBalanceData(numberOfColumns);
  const tableData = isLoading ? [...loadingData] : [...data];
  const headerData = tableData.shift() ?? { attribute: '' };

  const columnHelper = createColumnHelper<(typeof tableData)[0]>();
  const columns = [
    columnHelper.accessor('attribute', {
      header: 'Attribute',
      cell: () => <AttributeCell frequency={frequency} type={type} />,
    }),
    ...Object.keys(headerData)
      .filter(key => key !== 'attribute')
      .map(key =>
        columnHelper.accessor(key, {
          header: headerData[key] as string,
          cell: info => <BalanceCell balance={info.getValue() as BalanceData} />,
        })
      ),
  ];

  return {
    tableData,
    columns,
  };
};

interface BalanceTableProps {
  type: BalanceTableType;
  data: TableData[];
  isLoading?: boolean;
  numberOfColumns?: number;
  frequency?: CashflowPeriodRate;
  currentDateIndex?: number;
}

export function BalanceTable({
  type,
  data,
  isLoading = false,
  numberOfColumns = 6,
  frequency,
  currentDateIndex,
  ...props
}: BalanceTableProps): ReactNode {
  const { tableData, columns } = useMemo(
    () => buildTableSchema(data, isLoading, numberOfColumns, type, frequency),
    [data, isLoading, numberOfColumns, type, frequency]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table
      aria-busy={isLoading}
      aria-live="polite"
      className={styles.balanceTable}
      data-test-balance-table
      {...props}
    >
      <colgroup>
        <col />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} scope="col">
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              const isCurrentDate = index === currentDateIndex;
              if (index === 0) {
                return (
                  <th
                    className={styles.rowHeader}
                    data-testid="row-header"
                    key={cell.id}
                    scope="row"
                  >
                    <TableCell align="left" isLoading={false}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  </th>
                );
              }
              return (
                <td
                  className={`${styles.rowCell} ${isCurrentDate ? styles.current : ''}`}
                  key={cell.id}
                >
                  <TableCell isLoading={isLoading}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
