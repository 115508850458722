import { type ReactElement, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { useState } from 'react';
import { AmountField, ChoiceChip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import styles from './limit-input.strict-module.css';

interface ExpensePermissionLimitPreDefType {
  value: string | null;
  tracker: string;
}

interface LimitInputProps {
  amount?: string;
  amountErrorMessage?: string;
  onAmountChange: (value: string | null) => void;
  preDefinedValues: readonly ExpensePermissionLimitPreDefType[];
  title: string;
}

export function LimitInput({
  amount,
  amountErrorMessage,
  onAmountChange,
  preDefinedValues,
  title,
  ...props
}: LimitInputProps): ReactElement {
  const { formatMessage, formatNumber } = useIntl();
  const segment = useEmberService('segment');

  const [isFocused, setIsFocused] = useState(false);
  const [currentAmount, setCurrentAmount] = useState<string | null>(amount ?? null);

  useEffect(() => {
    setCurrentAmount(amount ?? null);
  }, [amount]);

  const isUnlimited = currentAmount === null;

  const generateLabel = (value: string | null): string => {
    return value
      ? formatNumber(Number(value), {
          currency: CURRENCIES.EUR,
          minimumFractionDigits: 0,
          style: 'currency',
        })
      : formatMessage({ id: 'invitation-flow.expense-permissions.transfers.limits.none-chip' });
  };

  const handleChipClick = (item: ExpensePermissionLimitPreDefType): void => {
    handleAmountChange(item.value);

    segment.track(item.tracker, {
      amount: item.value !== null ? item.value : '0',
    });
  };

  const handleAmountChange = (value: string | null): void => {
    const newValue = value === '' ? null : value;
    setCurrentAmount(newValue);
    onAmountChange(newValue);
  };

  return (
    <section {...props}>
      <form>
        <label
          className={cx('mb-8', styles['value-container'])}
          data-test-limit-input-value-container
        >
          <AmountField
            amount={currentAmount}
            className={styles['amount-field']}
            currency="€"
            data-test-sepa-transfer-amount
            decimals={0}
            errorMessage={amountErrorMessage}
            label={title}
            minValue={0}
            onAmountChange={(value: string) => {
              handleAmountChange(value);
            }}
            onFocusChange={(isFocusedFlag: boolean) => {
              setIsFocused(isFocusedFlag);
            }}
          />

          {isUnlimited && !isFocused ? (
            <span className={styles['unlimited-value']} data-test-limit-input-unlimited-value>
              <FormattedMessage id="invitation-flow.expense-permissions.transfers.limits.unlimited" />
            </span>
          ) : null}
        </label>

        {preDefinedValues.map((item, index) => (
          <ChoiceChip
            data-test-amount-limit-radio={item.value}
            data-test-choice-chip={index}
            isChecked={item.value === currentAmount}
            key={Math.random()}
            name="transfer-limit-values"
            onChipClick={() => {
              handleChipClick(item);
            }}
            value={item.value ?? ''}
          >
            {generateLabel(item.value)}
          </ChoiceChip>
        ))}
      </form>
    </section>
  );
}
