import { useEffect, useState, type ReactNode } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { OverviewBox } from '@repo/domain-kit/shared';
import dayjs from 'dayjs';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  useUpcomingTransactions,
  type Statistics,
  type UpcomingTransaction,
} from 'qonto/react/hooks/use-upcoming-transactions';
import { useCreateUpcomingTransaction } from 'qonto/react/components/cash-flow/hooks/use-create-upcoming-transaction';
import { useUpdateUpcomingTransaction } from '../../hooks/use-update-upcoming-transaction';
import { UpcomingTransactionsTable } from './table';
import styles from './styles.strict-module.css';
import { normalizeData, type ChartData } from './utils/normalize-data';
import { FormModal } from './form-modal/form-modal';
import { UpcomingTransactionsProvider } from './upcoming-transactions-context';
import { UpcomingTransactionsChart } from './upcoming-transactions-chart';

interface UpcomingTransactionsProps {
  toggleFormModal: boolean;
  selectedTransactionType: string;
  selectedPeriod: '30' | '90';
  bankAccountIds: string;
  onHideFormModal?: () => void;
}

interface FormData {
  id: string;
  amount: string;
  expectedDate: string;
  name: string;
  side: 'debit' | 'credit';
  frequency: string;
}

export function UpcomingTransactions({
  toggleFormModal,
  bankAccountIds,
  selectedTransactionType,
  selectedPeriod,
  onHideFormModal,
}: UpcomingTransactionsProps): ReactNode {
  const MAX_TRANSACTIONS = 1000;
  const abilities = useEmberService('abilities');
  const { data, isPending } = useUpcomingTransactions(
    MAX_TRANSACTIONS,
    1,
    'pending',
    bankAccountIds,
    selectedPeriod
  );
  const { mutate: createUpcomingTransaction } = useCreateUpcomingTransaction();
  const { mutate: updateUpcomingTransaction } = useUpdateUpcomingTransaction();

  let chartData: ChartData[] = [];
  const canViewChart = abilities.can('view upcoming transactions chart cash-flow');

  const segment = useSegment();
  const [formModal, setFormModal] = useState(false);
  const [updatedTransaction, setUpdatedTransaction] = useState<FormData>();
  const [adjustedOutflowAmount, setAdjustedOutflowAmount] = useState<string | null>();
  const [adjustedOutflowDate, setAdjustedOutflowDate] = useState<{
    date: string | null;
    amount: string | null;
    originalDate: string | null;
    side: string | null;
  }>({ date: null, amount: null, originalDate: null, side: null });

  useEffect(() => {
    if (toggleFormModal) {
      setFormModal(true);
      setUpdatedTransaction(undefined);

      window.history.pushState({ modalOpen: true }, '');
    }

    const handlePopState = (): void => {
      setFormModal(false);
      onHideFormModal?.();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [toggleFormModal, onHideFormModal]);

  const handleEditUpcomingTransaction = (upcomingTransaction: UpcomingTransaction): void => {
    const {
      amount,
      due_date: dueDate,
      counterparty_name: counterpartyName,
      side,
      id,
    } = upcomingTransaction;
    const frequency = upcomingTransaction.metadata?.recurrence?.frequency || '';
    const formData = {
      id,
      amount: amount.value,
      expectedDate: dueDate,
      name: counterpartyName,
      side,
      frequency,
    };

    setUpdatedTransaction(formData);

    setFormModal(true);
  };

  const handleHover = (amount: string | null): void => {
    setAdjustedOutflowAmount(amount);
  };

  const handleUpdatedDateForPreviewLine = (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ): void => {
    setAdjustedOutflowDate({ date, amount, originalDate, side });
  };

  if (data) {
    chartData = normalizeData(data, selectedTransactionType);
  }

  const [statistics, setStatistics] = useState<Statistics>();
  const [toPaySubtitle, setToPaySubtitle] = useState<{
    value: string;
    currency: string;
    copy: string;
  }>();
  const [toCollectSubtitle, setToCollectSubtitle] = useState<{
    value: string;
    currency: string;
    copy: string;
  }>();

  useEffect(() => {
    if (data?.statistics) {
      const stats = data.statistics;
      setStatistics(stats);
      setToPaySubtitle({
        value: stats.to_pay.overdue.value,
        currency: stats.to_pay.overdue.currency,
        copy: 'cash-flow.upcoming-transactions.cockpit.to-pay-overdue',
      });
      setToCollectSubtitle({
        value: stats.to_collect.overdue.value,
        currency: stats.to_collect.overdue.currency,
        copy: 'cash-flow.upcoming-transactions.cockpit.to-collect-overdue',
      });
    }
  }, [data?.statistics]);

  const onHover = (periodDate: string, flow: string): void => {
    const formattedDate = dayjs(periodDate).format('YYYY-MM-DD');
    segment.track('compass_chart_hover', { date: formattedDate, flow });
  };

  const onPeriodClick = (periodDate: string): void => {
    // Format the periodDate to YYYY-MM-DD to match the format in the TransactionCounterpartyCell
    const formattedDate = dayjs(periodDate).format('YYYY-MM-DD');
    const element = document.querySelector(`[data-due-date='${formattedDate}']`);

    if (element) {
      // Use the standard scrollIntoView method which was working before
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const toggleModal = (): void => {
    if (formModal) {
      onHideFormModal?.();
    }
    setFormModal(prevState => !prevState);
  };

  const handleSubmitForm = (formData: FormData): void => {
    const { expectedDate, name, amount, side, frequency, id } = formData;
    let recurrenceValue = null;

    if (frequency !== 'none') {
      recurrenceValue = frequency;
    }

    if (updatedTransaction) {
      const formattedDate = dayjs(expectedDate).format('YYYY-MM-DD');
      updateUpcomingTransaction({
        upcomingTransactionId: id,
        payload: {
          amount: { currency: 'EUR', value: amount },
          expected_date: formattedDate,
          name,
          side,
          recurrence: {
            valid: true,
            value: recurrenceValue ? { frequency: recurrenceValue } : null,
          },
        },
      });
      segment.track('compass_manual-entry_edited');
    } else {
      createUpcomingTransaction({
        amount: { currency: 'EUR', value: amount },
        expected_date: expectedDate,
        name,
        side,
        recurrence: recurrenceValue ? { frequency: recurrenceValue } : null,
      });
      segment.track('compass_manual-entry_created');
    }

    toggleModal();
  };

  return formModal ? (
    <FormModal
      closeModal={() => {
        toggleModal();
      }}
      existingUpcomingTransactionData={updatedTransaction}
      onUpdate={handleSubmitForm}
    />
  ) : (
    <section className={styles.container}>
      <div className={styles.overview}>
        <OverviewBox
          amount={statistics?.current_balance}
          isLoading={isPending}
          titleText="cash-flow.balance.current-header"
        />
        <OverviewBox
          amount={statistics?.to_pay.total}
          amountSubtitle={toPaySubtitle}
          isLoading={isPending}
          shouldBlurText={!canViewChart}
          titleText="cash-flow.upcoming-transactions.cockpit.to-pay"
        />
        <OverviewBox
          amount={statistics?.to_collect.total}
          amountSubtitle={toCollectSubtitle}
          isLoading={isPending}
          shouldBlurText={!canViewChart}
          titleText="cash-flow.upcoming-transactions.cockpit.to-collect"
        />
      </div>
      <UpcomingTransactionsProvider>
        {chartData.length > 0 && (
          <UpcomingTransactionsChart
            adjustedOutflowAmount={adjustedOutflowAmount}
            adjustedOutflowDate={adjustedOutflowDate}
            // @ts-expect-error All the types are not shared between packages (qonto-js and domain-kit) yet
            data={chartData}
            isLoading={isPending}
            onHover={onHover}
            onPeriodClick={onPeriodClick}
          />
        )}
        <UpcomingTransactionsTable
          data={data}
          handleEditUpcomingTransaction={handleEditUpcomingTransaction}
          handleHover={handleHover}
          handleUpdatedDateForPreviewLine={handleUpdatedDateForPreviewLine}
          isPending={isPending}
          selectedFlowKey={selectedTransactionType}
        />
      </UpcomingTransactionsProvider>
    </section>
  );
}
