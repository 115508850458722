import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState, type ReactElement, type ReactNode } from 'react';
import { LottiePlayer } from '@repo/design-system-kit';
// @ts-expect-error -- cannot fix the error
import { variation } from 'ember-launch-darkly';
import dayjs from 'dayjs';
import cx from 'clsx';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';
import styles from './styles.strict-module.css';

interface SubscriptionCloseSuccessProps {
  transitionToNext: () => void;
  context: {
    provisionalDueDate: string;
  };
}

const parseSpanTag = (input: string | string[]): ReactNode => {
  return <span className={styles.purple}>{input}</span>;
};

export function SubscriptionCloseSuccess(props: SubscriptionCloseSuccessProps): ReactElement {
  const segment = useEmberService('segment');
  const intl = useEmberService('intl');
  const { formatMessage } = useIntl();

  const { organization } = useOrganizationManager();

  const [animationReady, setAnimationReady] = useState(false);

  const safeVariation = variation as (feature: string) => boolean;
  const hasAccountClosingRefund = safeVariation('feature--boolean-account-closing-refund');
  const isItalianOrganization = organization.isItalian;

  useEffect(() => {
    segment.track('account_closing_confirmation_displayed');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  const provisionalDueDate = intl.formatDate(dayjs(props.context.provisionalDueDate).toDate(), {
    format: 'long',
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <LottiePlayer
          className={cx('mb-32', !animationReady && styles.hidden)}
          loop={false}
          onDataReady={() => {
            setAnimationReady(true);
          }}
          path="/lotties/account-deactivation-success.json"
        />

        <h1 className="title-2 mb-16" data-test-success-title>
          <FormattedMessage id="subscription.account-closing.success.title" />
        </h1>

        <p className={cx(styles.reminder, 'body-1 mb-32')}>
          {isItalianOrganization && hasAccountClosingRefund ? (
            <span className={styles['success-subtitle']} data-test-success-subtitle>
              {formatMessage(
                { id: 'subscription.account-closing.success.subtitle' },
                {
                  provisionalDueDate,
                  span: parseSpanTag,
                }
              )}
            </span>
          ) : (
            <>
              <span data-test-success-prefix>
                <FormattedMessage id="subscription.account-closing.success.description-prefix" />
              </span>{' '}
              <span className={styles.purple} data-test-success-date>
                <SubscriptionCloseStepsClosingDate />
              </span>{' '}
              <span data-test-success-suffix>
                <FormattedMessage id="subscription.account-closing.success.description-suffix" />
              </span>
            </>
          )}
        </p>

        <button
          className="btn btn--primary"
          data-test-success-cta
          onClick={props.transitionToNext}
          type="button"
        >
          <FormattedMessage id="btn.got_it" />
        </button>
      </div>
    </div>
  );
}
