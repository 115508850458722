import { LOCALES_DEFINITION } from '@repo/shared-config/constants/locales';

export const COMPASS_NAVATIC_TOUR_URLS = {
  [LOCALES_DEFINITION.EN.code]: 'https://qonto.navattic.com/oja0sm4',
  [LOCALES_DEFINITION.FR.code]: 'https://qonto.navattic.com/57d0zz3',
  [LOCALES_DEFINITION.IT.code]: 'https://qonto.navattic.com/8si0lvw',
  [LOCALES_DEFINITION.ES.code]: 'https://qonto.navattic.com/zca0sqr',
  [LOCALES_DEFINITION.DE.code]: 'https://qonto.navattic.com/4of0gso',
  [LOCALES_DEFINITION.PT.code]: 'https://qonto.navattic.com/1ha0au6',
};
