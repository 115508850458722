import { type ReactNode } from 'react';

export function LightningOutlined(): ReactNode {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" fill="none">
      <path
        d="M9.91487 32.0024C9.69487 32.0024 9.47487 31.9624 9.27487 31.8824C8.47487 31.6024 7.99487 30.8424 8.03487 30.0024L8.77487 18.3224H2.07487C1.37487 18.3224 0.734871 17.9424 0.414871 17.3224C0.0948714 16.7024 0.134871 15.9624 0.534871 15.3824L10.7349 0.802356C11.2149 0.122356 12.0549 -0.157644 12.8349 0.0823556C13.6149 0.322356 14.1549 1.04236 14.1549 1.86236V11.7624H21.9149C22.6149 11.7624 23.2549 12.1624 23.5949 12.7824C23.9149 13.4224 23.8549 14.1624 23.4349 14.7424L11.4349 31.2224C11.0749 31.7224 10.5149 32.0024 9.93487 32.0024H9.91487ZM9.77487 16.4424C10.0349 16.4424 10.2749 16.5424 10.4549 16.7424C10.6349 16.9224 10.7349 17.1824 10.7149 17.4424L10.6549 18.6824L9.93487 30.1424L21.9149 13.6624H13.2149C12.6949 13.6624 12.2749 13.2624 12.2749 12.7424V1.88236L2.07487 16.4624L9.77487 16.4424Z"
        fill="currentColor"
      />
    </svg>
  );
}
