import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice.ts';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice.ts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { WidgetInvoicesReceivableItems } from './components/receivable-items';
import { WidgetInvoicesSupplierItems } from './components/supplier-items';
import { WidgetInvoicesEmpty } from './components/empty';
import { WidgetInvoicesCtaBtn } from './components/cta-btn';
import { WidgetInvoicesLoading } from './components/loading';
import { WidgetInvoicesError } from './components/error';
import styles from './styles.strict-module.css';

type InvoiceType = 'receivable' | 'supplier';

export interface BaseWidgetInvoicesProps extends ComponentPropsWithoutRef<'div'> {
  invoiceType: InvoiceType;
  invoicingActivationStatus: 'preactivated' | 'activated';
  isError: boolean;
  isLoading: boolean;
  reload: () => void;
}

interface NullableInvoicesProps extends BaseWidgetInvoicesProps {
  invoiceType: InvoiceType;
  invoices: null;
}
interface ReceivableInvoicesProps extends BaseWidgetInvoicesProps {
  invoiceType: 'receivable';
  invoices: ReceivableInvoiceModel[];
}
interface SupplierInvoicesProps extends BaseWidgetInvoicesProps {
  invoiceType: 'supplier';
  invoices: SupplierInvoiceModel[];
}

type WidgetInvoicesProps = ReceivableInvoicesProps | SupplierInvoicesProps | NullableInvoicesProps;

export function WidgetInvoices({
  invoices,
  invoicingActivationStatus,
  invoiceType,
  isError,
  isLoading,
  reload,
  ...htmlAttributes
}: WidgetInvoicesProps): ReactNode {
  const { organization } = useOrganizationManager();
  const segment = useSegment();

  const showAllUrl =
    invoiceType === 'receivable'
      ? `/organizations/${organization.slug}/receivable-invoices?sortBy=due_date%2C-number&status=unpaid`
      : `/organizations/${organization.slug}/supplier-invoices?sortBy=due_date%3Aasc&status=to_review%2Cawaiting_payment&tab=all_invoices`;

  const handleShowAllClick: () => void = () => {
    if (invoiceType === 'receivable') {
      segment.track('overview_widget-client-invoices_show-all_clicked');
    } else {
      segment.track('overview_widget-supplier-invoices_show-all_clicked');
    }
  };

  if (isLoading) return <WidgetInvoicesLoading />;

  if (!invoices?.length && !isError) {
    return (
      <WidgetInvoicesEmpty
        invoiceType={invoiceType}
        invoicingActivationStatus={invoicingActivationStatus}
        organizationSlug={organization.slug}
        {...htmlAttributes}
      />
    );
  }

  const renderContent = (): ReactNode => {
    if (!invoices) return null;

    if (invoiceType === 'receivable') {
      return (
        <WidgetInvoicesReceivableItems
          invoiceType={invoiceType}
          invoices={invoices}
          organizationSlug={organization.slug}
        />
      );
    }
    return (
      <WidgetInvoicesSupplierItems
        invoiceType={invoiceType}
        invoices={invoices}
        organizationSlug={organization.slug}
      />
    );
  };

  return (
    <div
      className={styles.wrapper}
      {...{ [`data-test-widget-invoices-${invoiceType}`]: true }}
      {...htmlAttributes}
    >
      <div className={styles.header}>
        <h3 className={styles['header-title']} data-test-title>
          <FormattedMessage
            id={
              invoiceType === 'receivable'
                ? 'qonto-invoicing.overview.widgets.client-invoice.active-state.title'
                : 'qonto-invoicing.overview.widgets.supplier-invoice.active-state.title'
            }
          />
        </h3>
        <Link
          className={`btn btn--tertiary ${styles['show-all-btn']}`}
          data-test-show-all
          onClick={handleShowAllClick}
          to={showAllUrl}
        >
          <FormattedMessage
            id={
              invoiceType === 'receivable'
                ? 'qonto-invoicing.overview.widgets.client-invoice.active-state.show-all-cta'
                : 'qonto-invoicing.overview.widgets.supplier-invoice.active-state.show-all-cta'
            }
          />
        </Link>
      </div>
      <div className={styles.content}>
        {isError ? <WidgetInvoicesError reload={reload} /> : renderContent()}
      </div>
      <WidgetInvoicesCtaBtn
        invoiceType={invoiceType}
        invoicingActivationStatus={invoicingActivationStatus}
        organizationSlug={organization.slug}
      />
    </div>
  );
}
