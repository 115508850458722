import { type ReactElement } from 'react';
import {
  Sidebar as BaseSidebar,
  type SidebarProps as BaseSidebarProps,
  type SidebarSlots,
} from '@repo/domain-kit/shared';
import { type CardAcquirerPaymentModel } from 'qonto/react/card-acquirer/models/card-acquirer-payment';
import { Header } from 'qonto/react/card-acquirer/components/payments/sidebar/header';
import { PaymentDetails } from './payment-details';

interface SidebarProps extends BaseSidebarProps {
  payment: CardAcquirerPaymentModel;
}

function getSlots(payment?: CardAcquirerPaymentModel): SidebarSlots | undefined {
  if (!payment) {
    return undefined;
  }

  const contents = [<PaymentDetails key="payment-details" payment={payment} />];

  return {
    header: <Header payment={payment} />,
    contents,
  };
}

export function Sidebar({ isOpened, onClose, payment, title }: SidebarProps): ReactElement {
  return (
    <BaseSidebar isOpened={isOpened} onClose={onClose} slots={getSlots(payment)} title={title} />
  );
}
