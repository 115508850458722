import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useIntl } from 'react-intl';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path.ts';

export function UpcomingTransactionsTableEmptyState(): React.ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/upcoming-transactions/upcoming-transactions.json');

  return (
    <div data-testid="upcoming-transactions-table-empty-state">
      <EmptyStatesInform
        lottieSrc={lottieSrc}
        subtitle={formatMessage({
          id: 'upcoming-transactions.empty-state.no-transactions.subtitle',
        })}
        title={formatMessage({ id: 'upcoming-transactions.empty-state.no-transactions.title' })}
      />
    </div>
  );
}
