import type { ReactNode } from 'react';
import { Button, ExternalLink } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage.ts';
import { COMPASS_NAVATIC_TOUR_URLS } from 'qonto/react/components/cash-flow/constants/product-tour.ts';
import styles from './chart-upsell-overlay.strict-module.css';

const COMPASS_FTE_TOUR_COMPLETED = 'cashflow-compass-first-time-experience';

export function ChartUpsellOverlay(): ReactNode {
  const { formatMessage, locale } = useIntl();
  const upsellManager = useEmberService('upsell-manager');
  const hasCompletedTour = safeLocalStorage.getItem(COMPASS_FTE_TOUR_COMPLETED) === 'true';

  const onClickCompassTour = (): void => {
    safeLocalStorage.setItem(COMPASS_FTE_TOUR_COMPLETED, 'true');
  };

  const onStartFreeTrial = (): void => {
    void upsellManager.upgradeRecommendationWithTransition('cfm_upcoming_transactions_actions');
  };

  return (
    <section className={styles['chart-upsell-overlay']} data-testid="compass-chart-upsell-overlay">
      <div className={styles['chart-upsell-overlay-text']}>
        <h4 className="title-4" data-testid="compass-chart-upsell-overlay-heading">
          {hasCompletedTour
            ? formatMessage({
                id: 'cash-flow.first-time-experience.chart-overlay.compass-free-trial.heading',
              })
            : formatMessage({
                id: 'cash-flow.first-time-experience.chart-overlay.compass-tour.heading',
              })}
        </h4>
        <p className="caption" data-testid="compass-chart-upsell-overlay-description">
          {hasCompletedTour
            ? formatMessage({
                id: 'cash-flow.first-time-experience.chart-overlay.compass-free-trial.body',
              })
            : formatMessage({
                id: 'cash-flow.first-time-experience.chart-overlay.compass-tour.body',
              })}
        </p>
      </div>
      {hasCompletedTour ? (
        <Button
          data-testid="compass-chart-upsell-overlay-cta"
          onPress={onStartFreeTrial}
          size="medium"
          variant="primary"
        >
          {formatMessage({
            id: 'cash-flow.first-time-experience.chart-overlay.compass-free-trial.cta',
          })}
        </Button>
      ) : (
        <ExternalLink
          className={styles['tour-btn']}
          data-testid="compass-chart-upsell-overlay-cta"
          display="button"
          href={COMPASS_NAVATIC_TOUR_URLS[locale]}
          onPress={onClickCompassTour}
        >
          {formatMessage({
            id: 'cash-flow.first-time-experience.chart-overlay.compass-tour.cta',
          })}
        </ExternalLink>
      )}
    </section>
  );
}
