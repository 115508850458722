import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { Avatar, Tooltip } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { IconWarningSignFilled } from '@repo/monochrome-icons';
import type { BaseWidgetInvoicesProps } from '../widget-invoices';
import styles from '../styles.strict-module.css';
import { WidgetInvoicesItemMissing } from './item-missing';

interface WidgetInvoicesItemProps extends ComponentPropsWithoutRef<'li'> {
  amount?: {
    currency?: string;
    value?: string;
  };
  date?: string;
  hasDuplicate?: boolean;
  id: string;
  invoiceType: BaseWidgetInvoicesProps['invoiceType'];
  name?: string;
  organizationSlug: string;
  overdue?: boolean;
  status?: string;
}

export function WidgetInvoicesItem({
  amount,
  date,
  hasDuplicate,
  id,
  invoiceType,
  name,
  organizationSlug,
  overdue,
  status,
}: WidgetInvoicesItemProps): ReactNode {
  const segment = useSegment();
  const { formatDate, formatMessage } = useIntl();

  const getTrackingType: () => string = () => {
    if (!date) return 'missing_data';
    if (overdue) return 'overdue';
    return 'due';
  };

  // If date is missing send missing data tracker else send overdue tracking status
  const handleViewInvoiceClick: () => void = () => {
    const trackingType = getTrackingType();
    if (invoiceType === 'receivable') {
      segment.track('overview_widget-client-invoices_invoice_clicked', {
        type: trackingType,
      });
    } else {
      segment.track('overview_widget-supplier-invoices_invoice_clicked', {
        type: trackingType,
      });
    }
  };

  return (
    <Link
      className={styles['invoices-list-item-link']}
      data-test-invoices-list-item
      onClick={handleViewInvoiceClick}
      to={`/organizations/${organizationSlug}/${invoiceType}-invoices/${id}?origin=overview`}
    >
      <li className={styles['invoices-list-item']}>
        {name ? (
          <Avatar name={name.split(' ').shift()} />
        ) : (
          <div className={styles['empty-avatar']} />
        )}

        <div className={styles['name-status']}>
          <div className={styles.name} data-test-customer-name>
            {name ? (
              <div className={styles['name-title']}>{name}</div>
            ) : (
              <WidgetInvoicesItemMissing />
            )}
            {hasDuplicate ? (
              <Tooltip
                label={formatMessage({ id: 'receivable-invoices.table.preview.duplicate-tooltip' })}
                needsButton
                placement="top"
                tooltipTestId="data-test-tooltip"
              >
                <IconWarningSignFilled className={styles['icon-warning']} data-test-icon-warning />
              </Tooltip>
            ) : null}
          </div>
          <div className={styles.status} data-test-status>
            {status || <WidgetInvoicesItemMissing />}
          </div>
        </div>
        <div className={styles['amount-date']}>
          <div className={styles.amount} data-test-amount>
            {amount?.value && amount.currency ? (
              <FormattedNumber
                currency={amount.currency}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                style="currency"
                value={Number(amount.value)}
              />
            ) : (
              <WidgetInvoicesItemMissing />
            )}
          </div>
          <div className={cx(styles.date, overdue ? styles.overdue : undefined)} data-test-date>
            {date ? (
              <>
                <FormattedMessage
                  id="qonto-invoicing.overview.widgets.invoices.unpaid-invoice.due-date"
                  values={{
                    invoiceDueDate: formatDate(date, {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    }),
                  }}
                />
                {overdue ? (
                  <IconWarningSignFilled className={styles['icon-error']} data-test-icon-error />
                ) : null}
              </>
            ) : (
              <WidgetInvoicesItemMissing />
            )}
          </div>
        </div>
      </li>
    </Link>
  );
}
