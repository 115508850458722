import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';

interface FlowLinkManager {
  transitionTo: (options: {
    name: string;
    stepId: string;
    queryParams: { supplierInvoiceId: string; origin: string };
  }) => void;
}

export const usePayNow = (upcomingTransaction: UpcomingTransaction | null): (() => void) => {
  const segment = useSegment();
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;

  const type = upcomingTransaction?.provider_object_type;
  const invoiceId = upcomingTransaction?.provider_object_id;
  const localAmountCurrency = upcomingTransaction?.local_amount.currency;
  const isFX = localAmountCurrency !== undefined && localAmountCurrency !== 'EUR';

  return (): void => {
    segment.track('compass_action_clicked', {
      action: 'pay_now',
      type,
    });

    if (invoiceId === undefined) return;

    if (isFX) {
      flowLinkManager.transitionTo({
        name: 'international-out',
        stepId: 'quote',
        queryParams: {
          supplierInvoiceId: invoiceId,
          origin: 'upcoming-transactions',
        },
      });
    } else {
      flowLinkManager.transitionTo({
        name: 'sepa-transfer',
        stepId: 'summary',
        queryParams: {
          supplierInvoiceId: invoiceId,
          origin: 'upcoming-transactions',
        },
      });
    }
  };
};
