import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CardLevel } from '@repo/cards-kit/constants';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { CARD_LEVELS_TRACKING, ORIGIN } from 'qonto/constants/cards';
import type { Estimates } from 'qonto/react/cards/types/estimates';
import type { CardLimits } from 'qonto/react/cards/types/limits';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design';

interface UseChooseCardProps {
  context: {
    bankAccount: {
      authorizedBalance: number;
      name: string;
    };
    card: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- too complex to type
      setProperties: (props: any) => void;
    };
    cardsLimits: CardLimits;
    estimates: Partial<Estimates>;
    flowTrackingOrigin: string;
    hasMultipleActiveAccounts: boolean;
    origin: string;
  };
  onChooseCard: (digitalCardSecondaryFlow: string, stepId: string) => void;
}

export function useChooseCard({ context, onChooseCard }: UseChooseCardProps): {
  handleChooseCard: (cardLevel: CardLevel) => Promise<void>;
} {
  const cardsManager = useEmberService('cardsManager');
  const abilities = useEmberService('abilities');
  const organizationManager = useEmberService('organizationManager');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const { formatMessage } = useIntl();

  const handleChooseCard = async (cardLevel: CardLevel): Promise<void> => {
    const {
      bankAccount,
      card,
      cardsLimits,
      flowTrackingOrigin,
      hasMultipleActiveAccounts,
      origin,
    } = context;

    let estimates = context.estimates;

    try {
      // when coming back from the subscription change flow, we have to re-fetch the card costs which were initially missing (like the Instant card costs)
      if (!estimates[cardLevel]) {
        const { organization } = organizationManager;
        const cardsCosts = await cardsManager.fetchCardsCostsTask.perform(organization.id);
        estimates = cardsCosts.estimates as Estimates;
        context.estimates = estimates;
      }

      //@ts-expect-error -- estimates[cardLevel] is defined at line 52
      if (bankAccount.authorizedBalance < estimates[cardLevel].amount) {
        const singleMessage = formatMessage({ id: 'cards.new.choose-type.balance-warning.single' });
        const multiMessage = formatMessage(
          { id: 'cards.new.choose-type.balance-warning.multi' },
          { accountName: bankAccount.name }
        );

        const toastMessage = hasMultipleActiveAccounts ? multiMessage : singleMessage;
        toastFlashMessages.toastError(toastMessage);
        return;
      }

      const limits = cardsLimits[cardLevel];
      const cardDesign = getDefaultDesignByLevel(cardLevel);

      if (!('payment_monthly_limit_maximum' in limits)) {
        card.setProperties({
          cardDesign,
          cardLevel,
          paymentLifespanLimit: Math.max(...limits.payment_lifespan_limit),
          preExpiresAt: dayjs().endOf('day').toDate(),
        });
      } else {
        card.setProperties({
          cardDesign,
          cardLevel,
          paymentMonthlyLimit: limits.payment_monthly_limit_maximum,
        });
      }

      segment.track('cards_order_virtual_type_chosen', {
        card_type: CARD_LEVELS_TRACKING[cardLevel],
        origin: flowTrackingOrigin,
      });

      if (hasMultipleActiveAccounts) {
        onChooseCard(`card-new-${cardLevel}`, `bank-account`);
        return;
      }

      let stepId = 'holder';

      if (origin === ORIGIN.MEMBER_INVITATION) {
        switch (cardLevel) {
          case CardLevel.Virtual:
            stepId = abilities.can('view restrictions card') ? 'restrictions' : 'payment-limits';
            break;
          case CardLevel.Flash:
            stepId = 'payment-lifespan-limit';
            break;
          case CardLevel.Advertising:
            stepId = 'payment-limits';
            break;
          default:
            break;
        }
      }

      onChooseCard(`card-new-${cardLevel}`, stepId);
    } catch (error: unknown) {
      if ((error as { shouldSendToSentry?: boolean }).shouldSendToSentry) {
        sentry.captureException(error);
      }
      toastFlashMessages.toastError(formatMessage({ id: 'errors.internal_server_error' }));
    }
  };

  return { handleChooseCard };
}
