import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { IconEyeHiddenOutlined } from '@repo/monochrome-icons';
import { Button, Tooltip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { CellContext } from '@tanstack/react-table';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { PopupDestructive } from 'qonto/react/components/popups/destructive';
import { useDeleteUpcomingTransaction } from '../../../../hooks/use-delete-upcoming-transaction';
import { getDestructiveModalProps } from '../utils/get-destructive-modal-props';

function HideButton({
  upcomingTransaction,
}: {
  upcomingTransaction: UpcomingTransaction;
}): ReactNode {
  const reactPopup = useEmberService('reactPopup');
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const deleteUpcomingTransaction = useDeleteUpcomingTransaction();

  const handleDelete = async (): Promise<void> => {
    segment.track('compass_action_clicked', {
      action: 'remove',
      type: upcomingTransaction.provider_object_type,
    });

    const { titleId, subtitleId, confirmTextId, cancelTextId } =
      getDestructiveModalProps(upcomingTransaction);
    const isRecurring = upcomingTransaction.metadata?.recurrence !== undefined;

    const result = await reactPopup.open(PopupDestructive, {
      title: formatMessage({ id: titleId }),
      subtitle: formatMessage({ id: subtitleId }),
      confirmText: formatMessage({ id: confirmTextId }),
      cancelText: formatMessage({ id: cancelTextId }),
    });

    if (isRecurring) {
      if (result === 'cancel') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: false,
        });
        return;
      }
      if (result === 'confirm') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: true,
        });
        return;
      }
    }
    if (!isRecurring) {
      if (result === 'confirm') {
        deleteUpcomingTransaction.mutate({
          upcomingTransactionId: upcomingTransaction.id,
          cascade: true,
        });
      }
    }
  };

  return (
    <Tooltip
      label={<FormattedMessage id="cash-flow.upcoming-transactions.remove.tooltip" />}
      placement="top"
    >
      <Button data-testid="hide-icon" iconOnly onPress={handleDelete} variant="tertiary">
        <IconEyeHiddenOutlined />
      </Button>
    </Tooltip>
  );
}

export function HideButtonCell(info: CellContext<UpcomingTransaction, unknown>): ReactNode {
  const abilities = useEmberService('abilities');
  const canViewActions = abilities.can('use upcoming transactions actions cash-flow');

  if (!canViewActions) return null;
  return <HideButton upcomingTransaction={info.row.original} />;
}
