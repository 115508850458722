import { LoadingState, type LoadingStateProps } from '@repo/domain-kit/shared';
import type { ReactNode } from 'react';
import { AiGlow } from 'qonto/react/components/account-receivable/summary';
import styles from './ai-loading-state.strict-module.css';

export function AiLoadingState(props: LoadingStateProps): ReactNode {
  return (
    <div className={styles.componentWrapper}>
      <AiGlow>
        <div className={styles.loadingStateWrapper}>
          <LoadingState isAIBranded {...props} />
        </div>
      </AiGlow>
    </div>
  );
}
