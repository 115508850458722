import type { ReactElement, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SuggestedProducts, type SuggestedProduct } from '@repo/domain-kit/pricing';
import { getProductName } from 'qonto/react/pricing/utils/products';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import { Addons } from 'qonto/constants/addons';
import { ConfirmHeader, type ProductInfo } from 'qonto/react/pricing/components/confirm-header';
import type { DataContext, PriceBreakdown } from '../data-context.type';
import { ConfirmDisclaimers } from '../confirm-disclaimers';
import styles from './styles.strict-module.css';

interface ConfirmUpgradeProps {
  header: ReactElement;
  context: DataContext;
  // TODO move addon and pricePlan logic here
  addon?: ProductInfo;
  pricePlan?: ProductInfo;
  isAddonOnFreeTrial?: boolean;
  onConfirm: () => void;
  isLoading?: boolean;
  onSuggestedProductToggle: (isSelected: boolean, product: SuggestedProduct) => void;
  hasInitialTrial?: boolean;
}

export function ConfirmUpgrade({
  header,
  context,
  addon,
  pricePlan,
  isAddonOnFreeTrial,
  onConfirm,
  isLoading,
  hasInitialTrial,
  onSuggestedProductToggle,
}: ConfirmUpgradeProps): ReactElement {
  const intl = useIntl();
  const { formatDate, formatMessage, formatNumber } = intl;
  const productDescriptions = {
    [Addons.AccountsPayable]: formatMessage({
      id: 'subscription.change.confirmation.addon-upsell.accounts-payable.subtitle',
    }),
    [Addons.AccountsReceivable]: formatMessage({
      id: 'subscription.change.confirmation.addon-upsell.accounts-receivable.subtitle',
    }),
    [Addons.ExpenseSpendManagement]: formatMessage({
      id: 'subscription.change.confirmation.addon-upsell.expense-spend.subtitle',
    }),
    [Addons.CashFlowManagement]: formatMessage({
      id: 'subscription.change.confirmation.addon-upsell.cash-flow-management.subtitle',
    }),
  };

  const [products, setProducts] = useState<PriceBreakdown[]>([]);
  const {
    total_estimate: totalEstimate,
    hasInsufficientFunds,
    isAddonBlocked,
    targetAddon,
    targetPricePlan,
    errors,
    warnings,
    suggestedProducts,
  } = context;

  const isCtaDisabled = hasInsufficientFunds || isAddonBlocked || isLoading;
  useEffect(() => {
    const productsArr: PriceBreakdown[] = [];
    if (targetPricePlan && pricePlan?.title) {
      productsArr.push({
        title: pricePlan.title,
        price: formatNumber(Number(targetPricePlan.prorated_amount.value), {
          style: 'currency',
          currency: targetPricePlan.prorated_amount.currency,
        }),
        testId: 'data-test-price-plan-breakdown',
      });
    }
    if (targetAddon && addon?.title) {
      productsArr.push({
        title: addon.title,
        price: formatNumber(Number(targetAddon.prorated_amount.value), {
          style: 'currency',
          currency: targetAddon.prorated_amount.currency,
        }),
        testId: 'data-test-addon-breakdown',
      });
    }

    setProducts(productsArr);
  }, [addon?.title, formatNumber, pricePlan?.title, targetAddon, targetPricePlan]);

  const getData = (
    product: ProductInfo
  ): {
    title: string;
    price: ReactNode;
    subtitle: ReactNode;
    priceNote: string;
  } => {
    return {
      title: product.title,
      price: product.price,
      subtitle: product.subtitle,
      priceNote: formatMessage({
        id: 'subscription.change.confirmation.addon.billing.price-note',
      }),
    };
  };

  const isAddonFree = (): boolean => {
    // TODO: check if the addon is free
    return Boolean(hasInitialTrial);
  };
  return (
    <ConfirmLayout header={header} testId="data-test-addon-change-confirm">
      <>
        <div className={styles.container}>
          <ConfirmHeader
            addon={targetAddon && addon ? getData(addon) : undefined}
            pricePlan={targetPricePlan && pricePlan ? getData(pricePlan) : undefined}
          />
          {suggestedProducts?.length ? (
            <SuggestedProducts
              onToggle={onSuggestedProductToggle}
              products={suggestedProducts.map(product => ({
                code: product.product_code,
                title: getProductName(intl, product.product_code),
                description: productDescriptions[product.product_code as Addons],
                price: product.prorated_amount,
                priceNote: isAddonFree()
                  ? formatMessage({
                      id: 'subscription.change.confirmation.addon-upsell.free-trial-price.subtitle',
                    })
                  : formatMessage({
                      id: 'subscription.change.confirmation.addon-upsell.prorated-price.subtitle',
                    }),
                // TODO: add isSelected
                isSelected: false,
                // TODO: add isFree
                isFree: false,
              }))}
              title={formatMessage({
                id: 'subscription.change.confirmation.addon-upsell.addon.title',
              })}
            />
          ) : null}
        </div>
        <ConfirmBillingSummary
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: formatDate(totalEstimate.next_billing_date, {
                format: 'long',
              }),
            }
          )}
          confirmButtonTestId="data-test-addon-change-confirm-next-step"
          confirmText={
            isAddonOnFreeTrial
              ? formatMessage({ id: 'subscription.change.addons.cta.free-trial' })
              : formatMessage({ id: 'btn.confirm' })
          }
          isCtaDisabled={isCtaDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(totalEstimate.vat_included_amount.value), {
            style: 'currency',
            currency: totalEstimate.vat_included_amount.currency,
          })}
          products={products}
          testId="data-test-addon-total"
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
          totalEstimate={totalEstimate}
        >
          <ConfirmDisclaimers
            addonName={targetAddon?.productName}
            errors={errors}
            hasInsufficientFunds={hasInsufficientFunds}
            planName={targetPricePlan?.productName}
            warnings={warnings}
          />
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}
