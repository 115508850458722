/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import {
  type FocusEvent,
  forwardRef,
  type ReactElement,
  type Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  AmountField,
  Button,
  ComboBox,
  ComboBoxSeparator,
  ComboBoxOption,
  Disclaimer,
  NumberField,
  SelectOption,
  TextAreaField,
  TextField,
} from '@repo/design-system-kit';
import {
  IconChevronBottomOutlined,
  IconChevronTopOutlined,
  IconPlusOutlined,
  IconTrashOutlined,
  IconWarningSignFilled,
} from '@repo/monochrome-icons';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { isNil } from 'es-toolkit';
import { useDebounceCallback } from 'usehooks-ts';
import { DragHandle, useSortable, VatRate, type VatRateOption } from '@repo/domain-kit/shared';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { mapInvoicingItemToProduct } from 'qonto/utils/products';
import type { ReceivableInvoiceItemModel } from 'qonto/react/receivable-invoices/api/models/receivable-invoices-item.ts';
import { getSupportedUnits, getVatExemptionReasons } from 'qonto/utils/receivable-invoicing.ts';
import { SDD_INDIVIDUAL_COLLECTION_LIMIT } from 'qonto/constants/direct-debit-collections.ts';
import {
  DEFAULT_PAYMENT_CONDITION,
  DEFAULT_PAYMENT_METHOD,
  EINVOICING_UNIT_CODES,
} from 'qonto/constants/receivable-invoice.ts';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice.ts';
import type QuoteModel from 'qonto/models/quote.ts';
import type InvoiceSubscriptionModel from 'qonto/models/invoice-subscription.ts';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager.ts';
import { useProductsCatalog } from 'qonto/react/hooks/use-products-catalog.ts';
import type ProductModel from 'qonto/models/product.ts';
import { type FormErrors, useForm } from 'qonto/react/hooks/use-form.ts';
import { DiscountField } from '../discount-field';
import styles from './styles.strict-module.css';

const MAX_TITLE_LENGTH = 120;
const UNIT_PRICE_LIMIT = 1000000000;
const VAT_RATE_PRECISION = 0.01;
const MAX_UNIT_PRICE_LENGTH_BEFORE_SEPARATOR = 9;
const PREDEFINED_VALUE = 'predefined_value';
const CUSTOM_VALUE = 'custom';
const LEADING_DEBOUNCE_OPTION = { leading: true, trailing: false };

interface ItemProps {
  document: ReceivableInvoiceModel | QuoteModel | InvoiceSubscriptionModel;
  item: ReceivableInvoiceItemModel;
  errors?: FormErrors<ReceivableInvoiceItemModel>;
  currency: string;
  index: number;
  hideUnit?: boolean;
  className?: string;
  isCreditNote?: boolean;
  shouldDisplayInternationalClientVatDisclaimer?: boolean;
  isDisabled?: boolean;
  isDraggable?: boolean;
  hideDiscount?: boolean;
  forcePositive?: boolean;
  onDeleteItem?: (item: ReceivableInvoiceItemModel) => void;
  onVatResetDisclaimerDismiss?: (dismiss: boolean) => void;
}

export const Item = forwardRef(ItemWithoutRef) as (
  p: ItemProps & { ref?: Ref<HTMLDivElement> }
) => ReactElement;

function ItemWithoutRef(
  {
    document,
    item,
    errors,
    currency,
    className,
    index,
    hideUnit,
    isCreditNote,
    shouldDisplayInternationalClientVatDisclaimer,
    isDisabled,
    isDraggable,
    hideDiscount,
    forcePositive,
    onDeleteItem,
    onVatResetDisclaimerDismiss,
    ...props
  }: ItemProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const { formatMessage, formatNumber, locale } = useIntl();
  const { organization } = useOrganizationManager();
  const modals = useEmberService('modals');
  const segment = useEmberService('segment');
  const toastFlashMessages = useEmberService('toast-flash-messages');
  const store = useEmberService('store');
  const { isSorting } = useSortable({ id: item.uuid });
  const [isOpen, setIsOpen] = useState(item.isOpen);
  const [previousTitle, setPreviousTitle] = useState(item.title);
  const vatExemptionReasons = getVatExemptionReasons(
    { t: (id: string) => formatMessage({ id }) },
    organization.legalCountry
  );
  const [vatExemptionReasonLabel, setVatExemptionReasonLabel] = useState(
    vatExemptionReasons.find(reason => reason.key === item.vatExemptionCode)?.label ?? ''
  );
  const [hasDropdownUnitBeenSelected, setHasDropdownUnitBeenSelected] = useState(false);
  const [hasTypedCustomUnit, setHasTypedCustomUnit] = useState<boolean>(false);
  const [showDiscountField, setShowDiscountField] = useState(Boolean(item.discount));
  const { fetchProducts, isLoading: isLoadingProducts, products } = useProductsCatalog();
  const { formState, getValue, setValue, getError, hasError, clearErrors } = useForm({
    defaultValues: item,
    errors,
    onUpdateValue(attribute, value): void {
      item[attribute] = value;
    },
    onClearErrors(attributes): void {
      attributes.forEach(attribute => {
        if (item.errors?.has(attribute)) {
          item.errors.remove(attribute);
        }
      });
    },
  });

  // useDebounceCallback rely on a static ref for the callback
  const trackItemAdded = useDebounceCallback(
    useRef(() => {
      segment.track('item_added-one-off');
    }).current,
    6000,
    LEADING_DEBOUNCE_OPTION
  );

  // useDebounceCallback rely on a static ref for the callback
  const trackDescriptionChange = useDebounceCallback(
    useRef(() => {
      segment.track('invoice_item-description_filled');
    }).current,
    6000,
    LEADING_DEBOUNCE_OPTION
  );

  // useDebounceCallback rely on a static ref for the callback
  const trackVatRateSelected = useDebounceCallback(
    useRef(() => {
      segment.track('invoice_item-VAT-chip_clicked');
    }).current,
    6000,
    LEADING_DEBOUNCE_OPTION
  );

  // useDebounceCallback rely on a static ref for the callback
  const trackVatRateChange = useDebounceCallback(
    useRef(() => {
      segment.track('invoice_item-VAT-other_filled');
    }).current,
    6000,
    LEADING_DEBOUNCE_OPTION
  );

  // useDebounceCallback rely on a static ref for the callback
  const handleSearchProducts = useDebounceCallback(
    useRef((search: string) => {
      fetchProducts({ query: search });
    }).current,
    DEBOUNCE_MS
  );

  useEffect(() => {
    if (!formState.isValid) {
      setIsOpen(true);
      item.isOpen = true;
    }
  }, [formState.isValid, item, setIsOpen]);

  useEffect(() => {
    setIsOpen(item.isOpen);
  }, [item.isOpen]);

  // We need this to always update form state with current model values
  // and properly preserve values for all fields when some of them are being changed
  useEffect(() => {
    if (item.quantity !== undefined && item.quantity !== getValue('quantity')) {
      setValue('quantity', item.quantity);
    }

    if (item.unitPrice !== undefined && item.unitPrice !== getValue('unitPrice')) {
      setValue('unitPrice', item.unitPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want to trigger on every render
  }, [item.description, item.quantity, item.unit, item.unitPrice, item.vatRate]);

  const decimalSeparator =
    new Intl.NumberFormat(locale).formatToParts(0.5).find(({ type }) => type === 'decimal')
      ?.value ?? '.';

  const unitList = getSupportedUnits({ t: (id: string) => formatMessage({ id }) });
  const shouldDisplayDiscountField = !hideDiscount && (isDisabled ? Boolean(item.discount) : true);
  const shouldDisplayVatExemptionField =
    (item.vatRate && parseFloat(item.vatRate) === 0) || hasError('vatExemptionCode');
  const shouldDisplayVatExemptionDisclaimer =
    shouldDisplayInternationalClientVatDisclaimer && item.vatRate && parseFloat(item.vatRate) === 0;

  const vatError = getError('vatRate')?.message;
  const vatErrorMessage = useMemo(() => {
    switch (vatError) {
      case 'required':
        return formatMessage({
          id: 'receivable-invoices.invoice-creation.errors.vat.required-field',
        });
      case 'invalid':
        return formatMessage({ id: 'receivable-invoices.invoice-creation.errors.vat-over-100' });
      default:
        return undefined;
    }
  }, [formatMessage, vatError]);

  const vatRatePercentage = useMemo(() => {
    if (isNil(item.vatRate)) {
      return '';
    }

    return String(Number(item.vatRate) * 100).replace(/[,.]/g, decimalSeparator);
  }, [decimalSeparator, item.vatRate]);

  const selectedVatOption = useMemo(() => {
    if (item.vatRate === undefined) {
      return undefined;
    }

    if (item.vatRate !== null) {
      const rate = parseFloat(item.vatRate);

      const vatCategory = organization.vatRatesCategories.find(
        vatRateCategory => Math.abs(vatRateCategory - rate * 100) < VAT_RATE_PRECISION
      );

      if (vatCategory !== undefined) {
        return {
          label: vatCategory.toString(),
          clearable: false,
          value: vatCategory,
        };
      }
    }

    return { label: '', clearable: true, value: -1 };
  }, [item.vatRate, organization.vatRatesCategories]);

  function handleDelete(): void {
    onDeleteItem?.(item);
  }

  function handleCollapse(): void {
    setIsOpen(!isOpen);
    item.isOpen = !isOpen;
    segment.track('item_collapse');
  }

  function handleProductChange(title: string): void {
    setValue('title', title);
    clearErrors('title');
    handleSearchProducts(title);
  }

  function handleOpenProducts(open: boolean): void {
    if (open) {
      fetchProducts({ query: item.title });
    }
  }

  function handleBlurProducts(event: FocusEvent<HTMLInputElement>): void {
    if (
      Boolean(event.relatedTarget) &&
      products.find(option => option.id === event.relatedTarget?.id)
    ) {
      return;
    } else if (event.target.value && event.target.value !== previousTitle) {
      setPreviousTitle(item.title);
      segment.track('invoice_item-title_filled');
    }

    setValue('title', event.target.value);
    clearErrors('title');
    trackItemAdded();
  }

  function handleItemSelect(selectedItem: ProductModel, shouldTrack = true): void {
    if (isLoadingProducts) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Ember models
    const { description, title, unit, unitPrice, vatRate, vatExemptionCode, links, type, id } =
      selectedItem;
    setValue('title', title);
    setValue('description', description);
    setValue('unit', unit);
    clearErrors(['title', 'description', 'unit']);

    const { legalCountry } = organization;
    const customerCountry = document.customer.get('billingAddress')?.countryCode;

    if (customerCountry && legalCountry !== customerCountry) {
      item.resetVat();
      setValue('vatRate', item.vatRate);
      clearErrors('vatRate');
    } else if (vatRate) {
      setValue('vatRate', String(vatRate));
      clearErrors('vatRate');
      if (shouldTrack) {
        segment.track('invoice_item-title_dropdown_selected');
      }
      handleVatExemptionReasonUpdate(vatExemptionCode);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Ember models
    setValue('links', links);
    setValue('type', type);
    setValue('productId', id);
    clearErrors(['links', 'type', 'productId']);

    if (unitPrice.currency === currency) {
      handleUnitPriceUpdate(unitPrice.value);
    }
    handleQuantityUpdate('1');

    segment.track('item_added-from-catalog');
  }

  function handleSaveProduct(): void {
    segment.track('catalog_add-new_clicked', { source: 'document-creation' });

    function onSaveSuccess(product?: ProductModel): void {
      segment.track('catalog_new-item_added', {
        source: 'document-creation',
      });
      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'products.creation-form.toasts.success.product-added' })
      );

      if (product) {
        handleItemSelect(product, false);
      }

      fetchProducts();
    }

    function onConfirmedDuplicate(): void {
      segment.track('catalog_new-item_duplicate-added', {
        source: 'document-creation',
      });
    }

    function onDiscardedDuplicate(): void {
      segment.track('catalog_new-item_duplicate-discarded', {
        source: 'document-creation',
      });
    }

    // @ts-expect-error -- weird type conversion
    const product = store.createRecord('product', mapInvoicingItemToProduct(item));

    modals.open('products/creation-form-modal', {
      isFullScreenModal: true,
      focusTrapOptions: {
        clickOutsideDeactivates: false,
        allowOutsideClick: true,
        escapeDeactivates: false,
      },
      onSaveSuccess,
      onConfirmedDuplicate,
      onDiscardedDuplicate,
      product,
    });
  }

  function handleSelectProduct(product: ProductModel): () => void {
    return () => {
      handleItemSelect(product, true);
    };
  }

  function handleDescriptionBlur(): void {
    setValue('description', item.description?.replace(/\n{1,}/g, '\n'));
    clearErrors('description');

    if (item.description) {
      trackDescriptionChange();
    }
  }

  function handleDescriptionChange(value: string): void {
    item.updateDescription(value);
    setValue('description', item.description);
    clearErrors('description');
  }

  function resetPaymentMethodAndConditions(): void {
    if (
      parseFloat(document.totalAmount) > SDD_INDIVIDUAL_COLLECTION_LIMIT &&
      'directDebitEnabled' in document &&
      document.directDebitEnabled
    ) {
      document.directDebitEnabled = false;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Ember types bla
      if ('payment' in document && document.payment?.method && document.payment?.conditions) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Ember types bla
        document.payment.method = DEFAULT_PAYMENT_METHOD;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Ember types bla
        document.payment.conditions = DEFAULT_PAYMENT_CONDITION;
      }
    }
  }

  function handleQuantityUpdate(value: string | null): void {
    let quantity = value ? parseFloat(value) : undefined;

    if (isCreditNote && quantity) {
      quantity = -quantity;
    }

    setValue('quantity', forcePositive && quantity ? Math.abs(quantity) : quantity);
    clearErrors('quantity');

    resetPaymentMethodAndConditions();
  }

  function handleUnitSelection(unitCode: string | number | null): void {
    if (typeof unitCode === 'string' || isNil(unitCode)) {
      setValue('unit', unitCode ?? undefined);
      clearErrors('unit');

      // the event will be recorded only one time per item each time the form will be opened
      if (!hasDropdownUnitBeenSelected) {
        segment.track('invoice-creation_item_unit');
        setHasDropdownUnitBeenSelected(true);
      }
    }
  }

  function handleUnitSelectionBlur(): void {
    if (item.unit && item.unit.length > 0) {
      setValue('unit', item.unit.trim());
      clearErrors('unit');

      if (!hasTypedCustomUnit) {
        const type = EINVOICING_UNIT_CODES.includes(item.unit) ? PREDEFINED_VALUE : CUSTOM_VALUE;

        segment.track('invoice-creation_item_unit_filled', { type });

        // the event will be recorded only one time per item each time the form will be opened
        setHasTypedCustomUnit(true);
      }
    }
  }

  function handleUnitPriceUpdate(unitPrice: string): void {
    const [beforeSeparator, afterSeparator] = unitPrice.split('.');

    let newPrice = beforeSeparator?.slice(0, MAX_UNIT_PRICE_LENGTH_BEFORE_SEPARATOR);
    if (afterSeparator) {
      newPrice = `${newPrice}.${afterSeparator}`;
    }

    if (forcePositive && newPrice) {
      newPrice = Math.abs(parseFloat(newPrice)).toString();
    }

    // We use a tracked property to trigger a rerender even when the value doesn't change
    // This ensures the input field stays in sync with the actual value
    setValue('unitPrice', newPrice);
    // @ts-expect-error BE returns the error as `currency`, not `unitPrice.currency`
    clearErrors(['unitPrice', 'currency']);

    resetPaymentMethodAndConditions();
  }

  function handleDeleteDiscount(): void {
    setValue('discount', null);
    clearErrors(['discount', 'discountAmount']);
    setShowDiscountField(false);
  }

  function handleAddDiscountField(): void {
    setShowDiscountField(true);
    segment.track('invoice-creation_item_add-discount');
  }

  function setItemVatRate(value: number | null | undefined): void {
    setValue('vatRate', isNil(value) ? value : String(value));

    if (value !== 0) {
      setValue('vatExemptionCode', null);
      setVatExemptionReasonLabel('');
    }

    if (value === 0) {
      if (shouldDisplayInternationalClientVatDisclaimer) {
        if (organization.legalCountry === 'IT') {
          setValue('vatExemptionCode', 'N3.2');
          setVatExemptionReasonLabel(
            vatExemptionReasons.find(reason => reason.key === 'N3.2')?.label ?? ''
          );
        } else if (organization.legalCountry === 'FR') {
          setValue('vatExemptionCode', 'S262');
          setVatExemptionReasonLabel(
            vatExemptionReasons.find(reason => reason.key === 'S262')?.label ?? ''
          );
        } else if (organization.legalCountry === 'DE') {
          setValue('vatExemptionCode', 'S4.1B');
          setVatExemptionReasonLabel(
            vatExemptionReasons.find(reason => reason.key === 'S4.1B')?.label ?? ''
          );
        }
      } else if (organization.legalCountry === 'IT') {
        setValue('vatExemptionCode', 'N2.2');
        setVatExemptionReasonLabel(
          vatExemptionReasons.find(reason => reason.key === 'N2.2')?.label ?? ''
        );
      }
    } else {
      setValue('vatExemptionCode', undefined);
      setVatExemptionReasonLabel('');
    }

    clearErrors(['vatRate', 'vatExemptionCode']);
  }

  function handleVatRateChange(newRate: string): void {
    setItemVatRate(parseFloat(newRate) / 100);

    // track event
    trackVatRateChange();
  }

  function handleVatOptionUpdate(option: VatRateOption | undefined): void {
    if (!isNil(option?.value) && option.value >= 0) {
      setItemVatRate(option.value / 100);

      // track event
      trackVatRateSelected();
    } else if (isNil(option?.value)) {
      setItemVatRate(undefined);
    } else {
      setItemVatRate(null);
    }
  }

  function handleVatExemptionReasonChange(value: string): void {
    setVatExemptionReasonLabel(value);
    if (!value) {
      setValue('vatExemptionCode', null);
      clearErrors('vatExemptionCode');
    }
  }

  function handleVatExemptionReasonUpdate(
    vatExemptionCode: string | number | null | undefined
  ): void {
    if (typeof vatExemptionCode === 'string' || isNil(vatExemptionCode)) {
      onVatResetDisclaimerDismiss?.(true);
      setValue('vatExemptionCode', vatExemptionCode);
      clearErrors('vatExemptionCode');
      const vatExemptionReason = vatExemptionReasons.find(
        reason => reason.key === vatExemptionCode
      );
      setVatExemptionReasonLabel(vatExemptionReason?.label ?? '');
    }
  }

  const showContent = isOpen && !isSorting;

  return (
    <div className={cx(styles.item, className)} {...props} ref={ref}>
      <div className={styles.summary}>
        {isDraggable ? <DragHandle className={styles.handle} id={item.uuid} /> : null}
        <h4 className={cx(styles.title, 'title-4')} data-test-title-item-header={item.uuid}>
          {formatMessage(
            { id: 'receivable-invoices.invoice-creation.item-number.title' },
            { number: index + 1 }
          )}
          {getValue('title') ? ` - ${getValue('title')}` : ''}
        </h4>

        {!isDisabled && onDeleteItem ? (
          <Button
            aria-label={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.aria-labels.remove-item',
            })}
            data-test-delete-item={item.uuid}
            iconOnly
            onPress={handleDelete}
            size="small"
            type="button"
            variant="tertiary"
          >
            <IconTrashOutlined aria-hidden />
          </Button>
        ) : null}

        <Button
          data-test-collapse-item={item.uuid}
          iconOnly
          onPress={handleCollapse}
          size="small"
          type="button"
          variant="tertiary"
        >
          {showContent ? (
            <IconChevronTopOutlined aria-hidden />
          ) : (
            <IconChevronBottomOutlined aria-hidden />
          )}
        </Button>
      </div>

      {showContent ? (
        <div className={styles.content}>
          {isDisabled ? (
            <TextField
              className="mb-16"
              data-test-item-title={item.uuid}
              isDisabled={isDisabled}
              label={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.title.label',
              })}
              maxLength={MAX_TITLE_LENGTH}
              placeholder={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.title.placeholder',
              })}
              value={getValue('title')}
            />
          ) : (
            <ComboBox
              allowsCustomValue
              allowsEmptyCollection
              className="mb-16"
              data-has-error={hasError('title')}
              data-test-item-title={item.uuid}
              data-test-item-title-combobox=""
              defaultFilter={() => true}
              errorMessage={
                hasError('title')
                  ? formatMessage({
                      id: 'receivable-invoices.invoice-creation.errors.title.required-field',
                    })
                  : undefined
              }
              inputValue={getValue('title')}
              isDisabled={isDisabled}
              label={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.title.label',
              })}
              maxLength={MAX_TITLE_LENGTH}
              menuTrigger="focus"
              onBlur={handleBlurProducts}
              onInputChange={handleProductChange}
              onOpenChange={handleOpenProducts}
              placeholder={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.title.placeholder',
              })}
              withArrowButton
            >
              <ComboBoxOption
                className={styles['product-new']}
                id="__new"
                key="__new"
                onAction={handleSaveProduct}
              >
                <IconPlusOutlined
                  className={cx('mr-8', styles['product-new-icon'])}
                  height={16}
                  width={16}
                />
                <span className={styles['product-new-label']}>
                  {getValue('title')
                    ? formatMessage(
                        { id: 'receivable-invoices.invoice-creation.items.cta.save-item' },
                        {
                          item: (
                            <span className={styles['product-new-title']}>{getValue('title')}</span>
                          ),
                        }
                      )
                    : formatMessage({ id: 'receivable-invoices.invoice-creation.items.cta.save' })}
                </span>
              </ComboBoxOption>
              {isLoadingProducts || products.length > 0 ? <ComboBoxSeparator /> : null}
              {isLoadingProducts ? (
                <ComboBoxOption
                  className={styles['loading-option']}
                  data-test-item-title-combobox-loading=""
                  id="__loading"
                  isDisabled
                  key="__loading"
                >
                  {formatMessage({ id: 'labels.select_loading_message' })}
                </ComboBoxOption>
              ) : (
                products.map(product => (
                  <ComboBoxOption
                    className={styles.product}
                    data-test-item-title-combobox-list-row=""
                    id={product.id}
                    key={product.id}
                    onAction={handleSelectProduct(product)}
                  >
                    <span className={cx('body-2', styles['product-title'])}>{product.title}</span>
                    {product.unitPrice.value ? (
                      <span className={styles['product-price']}>
                        {formatNumber(parseFloat(product.unitPrice.value), {
                          currency: product.unitPrice.currency,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: 'currency',
                          currencyDisplay: 'code',
                        })}
                        {product.unit ? `/${product.unit}` : ''}
                      </span>
                    ) : (
                      <span
                        className={styles['product-missing-details-container']}
                        data-test-item-missing-details=""
                      >
                        <span className={styles['product-missing-details-label']}>
                          {formatMessage({ id: 'products.list.table.labels.missing-details' })}
                        </span>
                        <IconWarningSignFilled className={styles['warning-icon']} />
                      </span>
                    )}
                    {product.internalNote ? (
                      <span className={cx('caption', styles['product-internal-note'])}>
                        {product.internalNote}
                      </span>
                    ) : null}
                  </ComboBoxOption>
                ))
              )}
            </ComboBox>
          )}

          <TextAreaField
            className="mb-16"
            data-test-item-description={item.uuid}
            isDisabled={isDisabled}
            label={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.description.label',
            })}
            maxLength={600}
            onBlur={handleDescriptionBlur}
            onChange={handleDescriptionChange}
            placeholder={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.description.placeholder',
            })}
            value={getValue('description')}
          />

          <div className={cx(styles['quantity-amount'], 'mb-16')}>
            <NumberField
              className={styles['quantity-input']}
              data-has-error={hasError('quantity')}
              data-test-item-quantity={item.uuid}
              errorMessage={
                hasError('quantity')
                  ? formatMessage({
                      id: 'receivable-invoices.invoice-creation.errors.quantity.required-field',
                    })
                  : undefined
              }
              isDisabled={isDisabled}
              label={formatMessage({
                id: 'receivable-invoices.invoice-creation.items.quantity.label',
              })}
              onChange={handleQuantityUpdate}
              placeholder={isCreditNote ? '-1' : '1'}
              value={getValue('quantity')}
            />
            {!hideUnit && (
              <ComboBox
                allowsCustomValue
                aria-label={formatMessage({
                  id: 'receivable-invoices.invoice-creation.items.unit.placeholder',
                })}
                className={styles['unit-select']}
                data-test-item-unit-dropdown={item.uuid}
                inputValue={getValue('unit')}
                isDisabled={isDisabled}
                label=""
                maxLength={20}
                menuTrigger="focus"
                onBlur={handleUnitSelectionBlur}
                onInputChange={handleUnitSelection}
                placeholder={formatMessage({
                  id: 'receivable-invoices.invoice-creation.items.unit.placeholder',
                })}
              >
                {unitList.map(unit => (
                  <SelectOption id={unit.unitCode} key={unit.unitCode}>
                    {unit.unitDisplayName}
                  </SelectOption>
                ))}
              </ComboBox>
            )}
          </div>

          <AmountField
            amount={getValue('unitPrice')}
            className="mb-16"
            currency={currency}
            data-has-error={hasError('unitPrice')}
            data-test-item-unit-price={item.uuid}
            errorMessage={
              hasError('unitPrice')
                ? formatMessage({
                    id: 'receivable-invoices.invoice-creation.errors.unit-price.required-field',
                  })
                : undefined
            }
            isDisabled={isDisabled}
            isWheelDisabled
            label={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.unit-price.label',
            })}
            onAmountChange={handleUnitPriceUpdate}
            placeholder={formatMessage({
              id: 'receivable-invoices.invoice-creation.items.unit-price.placeholder',
            })}
          />

          {shouldDisplayDiscountField ? (
            <div className="mb-16" data-test-discount-row={item.uuid}>
              {showDiscountField ? (
                <DiscountField
                  amountErrorMessage={
                    hasError('discountAmount')
                      ? formatMessage({
                          id: 'receivable-invoices.invoice-creation.items.discount.amount-error-message',
                        })
                      : undefined
                  }
                  currency={currency}
                  data-has-error={Boolean(hasError('discountAmount') || hasError('discount'))}
                  data-test-item-discount-field={item.uuid}
                  isDisabled={isDisabled}
                  item={item}
                  onDeleteDiscount={handleDeleteDiscount}
                  percentageErrorMessage={
                    hasError('discount')
                      ? formatMessage({
                          id: 'receivable-invoices.invoice-creation.items.discount.percentage-error-message',
                        })
                      : undefined
                  }
                  priceLimit={UNIT_PRICE_LIMIT}
                />
              ) : (
                <Button
                  data-test-item-add-discount-button={item.uuid}
                  onPress={handleAddDiscountField}
                  type="button"
                  variant="tertiary"
                >
                  <IconPlusOutlined className="mr-8" />
                  {formatMessage({ id: 'receivable-invoices.invoice-creation.items.discount.cta' })}
                </Button>
              )}
            </div>
          ) : null}

          <VatRate
            className={
              shouldDisplayVatExemptionField || shouldDisplayInternationalClientVatDisclaimer
                ? 'mb-16'
                : 'mb-4'
            }
            data-has-error={hasError('vatRate')}
            data-test-invoice-item-vat={item.uuid}
            errorMessage={vatErrorMessage}
            isDisabled={isDisabled}
            label={formatMessage({ id: 'receivable-invoices.invoice-creation.items.vat.label' })}
            selectedVatOption={selectedVatOption}
            updateVatOption={handleVatOptionUpdate}
            vatRate={vatRatePercentage}
            vatRateOnChange={handleVatRateChange}
            vatRates={organization.vatRatesCategories}
          />

          {shouldDisplayVatExemptionField ? (
            <ComboBox
              className={shouldDisplayInternationalClientVatDisclaimer ? 'mb-16' : undefined}
              data-has-error={hasError('vatExemptionCode')}
              data-test-item-vat-exemption={item.uuid}
              defaultItems={vatExemptionReasons}
              errorMessage={
                hasError('vatExemptionCode')
                  ? formatMessage({
                      id: 'receivable-invoices.invoice-creation.errors.missing-tax-exemption',
                    })
                  : undefined
              }
              inputValue={vatExemptionReasonLabel}
              isDisabled={isDisabled}
              label={formatMessage({
                id: 'receivable-invoices.invoice-creation.tax-exemption.label',
              })}
              menuTrigger="focus"
              onInputChange={handleVatExemptionReasonChange}
              onSelectionChange={handleVatExemptionReasonUpdate}
              placeholder={formatMessage({
                id: 'receivable-invoices.invoice-creation.tax-exemption.placeholder',
              })}
              selectedKey={getValue('vatExemptionCode')}
              withArrowButton
            >
              {option => (
                <ComboBoxOption id={option.key} key={option.key}>
                  {option.label}
                </ComboBoxOption>
              )}
            </ComboBox>
          ) : null}

          {shouldDisplayVatExemptionDisclaimer ? (
            <Disclaimer.Inline data-test-item-vat-reset-disclaimer level="warning">
              {formatMessage({
                id: 'receivable-invoices.invoice-creation.tax-exemption.review-disclaimer',
              })}
            </Disclaimer.Inline>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
