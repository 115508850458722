import type { ReactNode } from 'react';
import { ComboChart, type ComboChartProps } from '@repo/domain-kit/cashflow';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ChartUpsellOverlay } from 'qonto/react/components/cash-flow/components/upcoming-transactions/chart-upsell-overlay/chart-upsell-overlay.tsx';
import styles from './styles.strict-module.css';
import { useUpcomingTransactionsContext } from './upcoming-transactions-context';

export function UpcomingTransactionsChart({
  data,
  onPeriodClick,
  isLoading = false,
  onHover,
  adjustedOutflowAmount,
  adjustedOutflowDate,
}: ComboChartProps): ReactNode {
  const { state } = useUpcomingTransactionsContext();
  const { scheduledTransactionPreview } = state;
  const abilities = useEmberService('abilities');
  const canViewChart = abilities.can('view upcoming transactions chart cash-flow');

  const highlightedIndex = data.findIndex(
    item => item.date === scheduledTransactionPreview?.newDate
  );

  return (
    <div className={styles.chartContainer}>
      <ComboChart
        adjustedOutflowAmount={adjustedOutflowAmount}
        adjustedOutflowDate={adjustedOutflowDate}
        data={data}
        highlightedIndex={highlightedIndex}
        isLoading={isLoading}
        onHover={onHover}
        onPeriodClick={onPeriodClick}
        scheduledTransactionPreview={scheduledTransactionPreview}
        visibleTicks={[0, data.length - 1]}
      />
      {!canViewChart && <ChartUpsellOverlay />}
    </div>
  );
}
