import { type ReactNode, useMemo } from 'react';
import { BaseTable } from '@repo/domain-kit/shared';
import { LoadingTransactionRow } from '@repo/domain-kit/cashflow';
import {
  type UpcomingTransaction,
  type UpcomingTransactionsResponse,
} from 'qonto/react/hooks/use-upcoming-transactions';
import { columns } from './columns.tsx';
import { UpcomingTransactionsTableEmptyState } from './empty-state.tsx';
import styles from './styles.strict-module.css';

interface UpcomingTransactionsTableProps {
  data: UpcomingTransactionsResponse | undefined;
  isPending: boolean | undefined;
  selectedFlowKey?: string;
  handleHover: (amount: string | null) => void;
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void;
  handleUpdatedDateForPreviewLine: (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ) => void;
}

export function UpcomingTransactionsTable({
  data,
  isPending,
  selectedFlowKey,
  handleEditUpcomingTransaction,
  handleUpdatedDateForPreviewLine,
  handleHover,
}: UpcomingTransactionsTableProps): ReactNode {
  const filteredTransactions = useMemo(
    () => getFilteredTransactions(data?.upcoming_transactions, selectedFlowKey),
    [data?.upcoming_transactions, selectedFlowKey]
  );

  const isEmpty = !isPending && !filteredTransactions.length;

  if (isEmpty) {
    return (
      <div className={styles['table-empty-state']}>
        <UpcomingTransactionsTableEmptyState />
      </div>
    );
  }

  return (
    <div className={styles['table-container']}>
      <BaseTable
        columns={columns}
        data={filteredTransactions}
        enablePagination={false}
        enableRowHover
        isLoading={isPending}
        loadingComponent={LoadingTransactionRow()}
        meta={{
          handleEditUpcomingTransaction,
          handleHover,
          handleUpdatedDateForPreviewLine,
        }}
        pageSize={25}
        pageSizes={[25, 50, 100]}
        total={filteredTransactions.length}
      />
    </div>
  );
}

const getFilteredTransactions = (
  upcomingTransactions: UpcomingTransaction[] | undefined,
  selectedFlowKey?: string
): UpcomingTransaction[] => {
  if (upcomingTransactions) {
    if (selectedFlowKey === 'inflows') {
      return upcomingTransactions.filter(transaction => transaction.side === 'credit');
    } else if (selectedFlowKey === 'outflows') {
      return upcomingTransactions.filter(transaction => transaction.side === 'debit');
    }
  }

  return upcomingTransactions || [];
};
