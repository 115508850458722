import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.strict-module.css';

export function WidgetInvoicesItemMissing(): ReactNode {
  return (
    <div className={styles['missing-info']} data-test-missing-info>
      <FormattedMessage id="receivable-invoices.label.missing-data" />
    </div>
  );
}
