import { useCallback, type ReactNode } from 'react';
import { Badge, Button, Tooltip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status, type Transaction } from 'qonto/react/graphql';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { StopXS } from 'qonto/react/assets/icons/stop-xs';
import { OpenLink } from 'qonto/react/assets/icons/open-link';
import type { SepaTransferUpsellRecommendation } from 'qonto/services/upsell-manager';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import { CLAIM_STATUSES } from 'qonto/constants/transactions';
import styles from './styles.strict-module.css';

interface SubtitleProps {
  sepaTransferPromotionRecommendation: SepaTransferUpsellRecommendation | null;
  transaction: Transaction;
}

function Subtitle({ sepaTransferPromotionRecommendation, transaction }: SubtitleProps): ReactNode {
  if (sepaTransferPromotionRecommendation) {
    return (
      <div className={styles.subtitle}>
        <Tooltip
          label={sepaTransferPromotionRecommendation.translations.subtitle}
          needsButton
          placement="top"
        >
          <Badge
            data-test-sepa-transfer-upsell-badge
            text={sepaTransferPromotionRecommendation.translations.badgeText}
            type="save"
          />
        </Tooltip>
      </div>
    );
  }

  const hasPendingClaims = transaction.disputes.some(({ claim }: { claim: { status: string } }) => {
    // TODO revert as statement when CLAIM_STATUS is typed
    // This triggers a TS error in the pipeline but not locally
    return claim.status === (CLAIM_STATUSES as { review: 'in_review' }).review;
  }) as boolean;
  if (hasPendingClaims) {
    return (
      <p className={styles['in-dispute-status']} data-test-in-dispute-status>
        <FormattedMessage id="transactions.chargeback-status.disputing" />
      </p>
    );
  }

  return null;
}

export function TransactionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { counterpartyName: name, enrichmentData, status } = transaction;
  const avatar = enrichmentData.logo?.small;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const { highlightItem } = routeContextManager.useRouteContext();
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();
  const upsellManager = useEmberService('upsell-manager');
  const sepaTransferPromotionRecommendation: SepaTransferUpsellRecommendation | null =
    upsellManager.sepaTransferUpsellRecommendation(transaction, true);

  const openSidePanel = useCallback(() => {
    segment.track('tables_primary-column-cta_clicked', {
      has_upsell_entrypoint: Boolean(sepaTransferPromotionRecommendation),
    });
    document.querySelector('.row-highlight')?.classList.remove('row-highlight');
    highlightItem(transaction.id);

    const row = document.querySelector(`#transaction-row-${transaction.id}`);
    if (row) row.classList.add('row-highlight');
  }, [highlightItem, segment, sepaTransferPromotionRecommendation, transaction.id]);

  const icon = avatar ? (
    <StatusAvatar
      alt={name}
      dataTestId="avatar"
      hasBorder
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      size="28"
      src={avatar}
    />
  ) : (
    <StatusAvatar
      avatar="custom"
      dataTestId="custom-avatar"
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      name={name}
      size="28"
    />
  );

  const sidePanelButton = (
    <Tooltip
      closeDelay={0}
      delay={300}
      label={formatMessage({
        id: 'transactions.modular_table.open_transaction.tooltip',
      })}
      placement="top"
      tooltipTestId="tooltip"
    >
      <Button
        className={styles.button}
        data-testid="sidepanel-button"
        iconOnly
        onPress={openSidePanel}
        type="button"
        variant="tertiary"
      >
        <OpenLink />
      </Button>
    </Tooltip>
  );

  return (
    <BaseCell
      actionSlot={sidePanelButton}
      actionSlotAlignment="right"
      data-testid="transaction-cell"
      onCellClick={openSidePanel}
      showActionSlotOnRowHover
    >
      <DataWithIconCell
        cellSubtitleClassName={isDeclined ? styles.declined : undefined}
        cellTitleClassName={isDeclined ? cx(styles.declined, 'body-1') : 'body-1'}
        icon={icon}
        subtitle={
          <Subtitle
            sepaTransferPromotionRecommendation={sepaTransferPromotionRecommendation}
            transaction={transaction}
          />
        }
        title={name}
      />
    </BaseCell>
  );
}
