import { useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import { Header as BaseHeader } from '@repo/domain-kit/shared';
import {
  CardAcquirerPaymentStatus,
  type CardAcquirerPaymentModel,
} from 'qonto/react/card-acquirer/models/card-acquirer-payment';

interface HeaderProps {
  payment: CardAcquirerPaymentModel;
}

export function Header({ payment }: HeaderProps): ReactElement {
  const intl = useIntl();

  const formattedAmount = (): string => {
    const value = Number(payment.amount.value);
    const amount = intl.formatNumber(value, {
      style: 'decimal',
      minimumFractionDigits: 2,
      useGrouping: false,
    });
    const sign = value < 0 ? '-' : '+';

    return `${sign}\xA0${amount}\xA0${payment.amount.currency}`;
  };

  const isDisabled = payment.status === CardAcquirerPaymentStatus.Declined;

  return (
    <BaseHeader
      amount={formattedAmount()}
      amountIsDisabled={isDisabled}
      avatarIsDisabled={isDisabled}
      avatarSrc={payment.avatarInfo.smallLogo}
      badge={{ type: payment.displayedStatus.level, text: payment.displayedStatus.text }}
      name={intl.formatMessage({ id: 'tap-to-pay.payments.sidepanel.type' })}
    />
  );
}
