import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import type { BaseWidgetInvoicesProps } from '../widget-invoices';
import styles from '../styles.strict-module.css';
import { WidgetInvoicesItem } from './item';

interface WidgetInvoicesSupplierItemsProps extends ComponentPropsWithoutRef<'ol'> {
  invoices: Pick<
    SupplierInvoiceModel,
    'dueDate' | 'hasDuplicates' | 'id' | 'payableAmount' | 'status' | 'supplierName'
  >[];
  invoiceType: BaseWidgetInvoicesProps['invoiceType'];
  organizationSlug: string;
}

export function WidgetInvoicesSupplierItems({
  invoices,
  invoiceType,
  organizationSlug,
}: WidgetInvoicesSupplierItemsProps): ReactNode {
  const { formatMessage } = useIntl();
  const invoicesForPreview = invoices.slice(0, 3); // we only show 3 invoices

  const getInvoiceStatus: (status: string) => string | undefined = (status: string) => {
    if (status === INVOICE_STATUSES.toReview)
      return formatMessage({ id: 'supplier-invoices.preview.status.imported' });
    if (status === INVOICE_STATUSES.awaitingPayment)
      return formatMessage({ id: 'supplier-invoices.preview.status.to-pay' });
    return undefined;
  };

  return (
    <ol className={styles['invoices-list']} data-test-invoices-list>
      {invoicesForPreview.map(invoice => (
        <WidgetInvoicesItem
          amount={{
            value: invoice.payableAmount?.value,
            currency: invoice.payableAmount?.currency,
          }}
          date={invoice.dueDate}
          hasDuplicate={invoice.hasDuplicates}
          id={invoice.id}
          invoiceType={invoiceType}
          key={invoice.id}
          name={invoice.supplierName}
          organizationSlug={organizationSlug}
          overdue={invoice.dueDate ? dayjs().isAfter(new Date(invoice.dueDate)) : false}
          status={getInvoiceStatus(`${invoice.status}`)}
        />
      ))}
    </ol>
  );
}
