import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { CardLevel } from '@repo/cards-kit/constants';
import { IconArrowLeftOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Subscription } from 'qonto/models/organization-subscription';
import { ChooseCardLayout } from 'qonto/react/cards/components/flows/choose-card-layout';
import type { Estimates } from 'qonto/react/cards/types/estimates';
import type { CardLimits } from 'qonto/react/cards/types/limits';
import type { OptionPrices } from 'qonto/react/cards/types/option-price';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { AdvertisingCard } from './internal/advertising-card';
import { FlashCard } from './internal/flash-card';
import { Footer } from './internal/footer';
import { useChooseCard } from './internal/use-choose-card';
import { VirtualCard } from './internal/virtual-card';
import styles from './styles.strict-module.css';

interface ChooseCardDigitalProps {
  context: {
    bankAccount: {
      authorizedBalance: number;
      name: string;
    };
    card: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- too complex to type
      setProperties: (props: any) => void;
    };
    cardOptionsPrices: OptionPrices;
    cardsLimits: CardLimits;
    currentSubscription: Subscription | null;
    estimates: Estimates;
    pricePlan: {
      code: string;
    };
    flowTrackingOrigin: string;
    hasMultipleActiveAccounts: boolean;
    origin: string;
    recurrence: string;
    addonCode: string;
  };
  onUpsell?: (flowName: string, stepId: string) => void;
  onChooseCard: (digitalCardSecondaryFlow: string, stepId: string) => void;
}

export function ChooseCardDigital({
  context,
  onUpsell,
  onChooseCard,
}: ChooseCardDigitalProps): ReactNode {
  const abilities = useEmberService('abilities');
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager();
  const { handleChooseCard } = useChooseCard({ context, onChooseCard });

  const { cardsLimits, estimates, cardOptionsPrices, pricePlan, currentSubscription } = context;

  return (
    <>
      <Link
        aria-label={formatMessage({ id: 'btn.back' })}
        className={cx(styles['back-button'], 'btn btn--tertiary', 'btn--large', 'btn--icon-only')}
        data-test-choose-card-back
        to={`/organizations/${organization.slug}/cards/physical-or-virtual`}
      >
        <IconArrowLeftOutlined />
      </Link>

      <div className={styles.container}>
        <h1 className={cx('title-1', 'mb-32')} data-test-choose-card-title>
          <FormattedMessage id="cards.steps.choose-card.virtual-category.title" />
        </h1>

        <div className={styles['selectors-container']}>
          <ul className={cx(styles['cards-list'], 'mb-20')} data-test-choose-card-selectors>
            <ChooseCardLayout>
              <VirtualCard
                assetProps={{ isImageOnly: true, className: styles.asset }}
                cardLimit={cardsLimits[CardLevel.Virtual]}
                ctaProps={{ handleClick: () => handleChooseCard(CardLevel.Virtual) }}
                headerProps={{
                  cardEstimates: estimates[CardLevel.Virtual],
                  optionPrice: cardOptionsPrices[CardLevel.Virtual],
                }}
              />
              {abilities.can('view advertising card') && (
                <AdvertisingCard
                  assetProps={{ isImageOnly: true, className: styles.asset }}
                  ctaProps={{ handleClick: () => handleChooseCard(CardLevel.Advertising) }}
                  headerProps={{
                    cardEstimates: estimates[CardLevel.Advertising],
                    optionPrice: cardOptionsPrices[CardLevel.Advertising],
                  }}
                />
              )}
              {(abilities.can('view flash card') || abilities.can('update subscription')) && (
                <FlashCard
                  assetProps={{ isImageOnly: true, className: styles.asset }}
                  cardLimit={cardsLimits[CardLevel.Flash]}
                  ctaProps={{ handleClick: () => handleChooseCard(CardLevel.Flash) }}
                  flashCardUpsellProps={{
                    onUpsell: ({ recurrence, addonCode }) => {
                      context.recurrence = recurrence;
                      context.addonCode = addonCode;
                      onUpsell?.('addon-change', 'addons');
                    },
                    pricePlan,
                    activeTrialRemainingDays: currentSubscription?.activeTrialRemainingDays ?? 0,
                    hasInitialTrial: currentSubscription?.hasInitialTrial ?? false,
                    hasInitialTrialUpsell: currentSubscription?.hasInitialTrialUpsell ?? false,
                  }}
                  headerProps={{
                    cardEstimates: estimates[CardLevel.Flash],
                    optionPrice: cardOptionsPrices[CardLevel.Flash],
                    shouldHidePricing: abilities.cannot('view flash card'),
                  }}
                />
              )}
            </ChooseCardLayout>
          </ul>

          <Footer estimates={estimates} />
        </div>
      </div>
    </>
  );
}
