import { Button, useToast } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { IconLightningOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import dayjs from 'dayjs';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context.tsx';
import { useAutofillCashflowForecast } from 'qonto/react/hooks/use-autofill-cashflow-forecast.ts';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import styles from './forecast-selector.strict-module.css';

export function ForecastSelector(): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useSegment();
  const { selectedCategories, updateSidepanelMode } =
    cashFlowSidePanelManager.useCashFlowSidePanel();
  const { showToast } = useToast();
  const cashFlowManager = useEmberService('cash-flow-manager');

  const { mutate: handleForecastAutofill } = useAutofillCashflowForecast({
    onSuccess: async () => {
      await cashFlowManager.updateFirstTimeExperienceState({
        forecast: {
          first_time_experience_state: FIRST_TIME_EXPERIENCE_STATES.FORECAST_SET,
        },
      });
      updateSidepanelMode('flashForecast');
      showToast({
        text: formatMessage(
          { id: 'cash-flow.side-panel.forecast.autofill.success.toast' },
          {
            firstMonth: dayjs().format('MMMM YYYY'),
            lastMonth: dayjs().add(11, 'month').format('MMMM YYYY'),
          }
        ),
        type: 'success',
      });
    },
    onError: () => {
      showToast({
        text: formatMessage({ id: 'toasts.errors.generic' }),
        type: 'error',
      });
    },
  });

  const handleFlashForecastClick = (): void => {
    segment.track('cash-flow_fte_flash-forecast');
    if (selectedCategories[0]?.id) {
      handleForecastAutofill({ category_id: selectedCategories[0].id });
    }
  };

  const handleImportForecastClick = (): void => {
    segment.track('cash-flow_fte_import-your-forecast');
    updateSidepanelMode('importForecast');
  };

  return (
    <div className={styles.wrapper} data-testid="forecast-choice">
      <h4 className="title-4" data-testid="title">
        {formatMessage({ id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.title' })}
      </h4>
      <p className={styles.subheading} data-testid="subtitle">
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.subheading',
        })}
      </p>
      <Button
        className={cx(styles.button, 'mb-12')}
        data-testid="flash-forecast-cta"
        onPress={handleFlashForecastClick}
        variant="primary"
      >
        <IconLightningOutlined className={styles.lightningIcon} />
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.flash-forecast-cta',
        })}
      </Button>
      <Button
        className={styles.button}
        data-testid="import-forecast-cta"
        onPress={handleImportForecastClick}
        variant="secondary"
      >
        {formatMessage({
          id: 'cash-flow.first-time-experience.sidepanel.forecast-choice.import-forecast-cta',
        })}
      </Button>
    </div>
  );
}
