import { type ReactNode } from 'react';
import type { CellContext } from '@tanstack/react-table';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { ActionsComponent } from '../actions-component';

export function ActionsCell(
  info: CellContext<UpcomingTransaction, unknown>,
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void,
  handleHover: (amount: string | null) => () => void,
  handleUpdatedDateForPreviewLine: (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ) => void
): ReactNode {
  const abilities = useEmberService('abilities');
  const canViewActions = abilities.can('use upcoming transactions actions cash-flow');

  if (!canViewActions) return null;

  return (
    <ActionsComponent
      handleEditUpcomingTransaction={handleEditUpcomingTransaction}
      handleHover={handleHover}
      upcomingTransaction={info.row.original}
      updatedDateForPreviewLine={handleUpdatedDateForPreviewLine}
    />
  );
}
