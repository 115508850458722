import { DatePicker, Select, SelectOption } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import type { DateValue } from 'react-aria';
import { parseDate } from '@internationalized/date';
import dayjs from 'dayjs';
import { ForecastFormulaFrequency } from 'qonto/react/api/models/cash-flow-forecast-formula';
import styles from './forecast-entry-edit-recurrence.strict-module.css';
import { type ForecastEntryVarianceDropdownValue } from './forecast-entry-variance-dropdown';
import { ForecastEntryEditVariance } from './forecast-entry-edit-variance';

interface ForecastEntryEditRecurrenceProps {
  onEndDateChange: (value: DateValue | null) => void;
  onVarianceChange: (value: number, type: ForecastEntryVarianceDropdownValue) => void;
  endDate: DateValue | null;
  variance: number;
  varianceType: ForecastEntryVarianceDropdownValue;
  currencyCode?: string;
  frequency: ForecastFormulaFrequency;
  onFrequencyChange: (value: string | number) => void;
  selectedIntervalStart: dayjs.Dayjs;
}

export function ForecastEntryEditRecurrence({
  onEndDateChange,
  onVarianceChange,
  endDate,
  variance,
  varianceType,
  currencyCode,
  frequency,
  onFrequencyChange,
  selectedIntervalStart,
}: ForecastEntryEditRecurrenceProps): React.ReactNode {
  const { formatMessage } = useIntl();
  const todayDate = parseDate(dayjs().format('YYYY-MM-DD')) as unknown as DateValue;
  const minDate = parseDate(selectedIntervalStart.format('YYYY-MM-DD')) as unknown as DateValue;
  const maxDate = todayDate.add({ years: 4 });

  const handleVarianceValueChange = (value: string | null): void => {
    onVarianceChange(value ? parseFloat(value) : 0, varianceType);
  };

  const handleVarianceTypeChange = (value: ForecastEntryVarianceDropdownValue): void => {
    onVarianceChange(variance, value);
  };

  const frequencyOptions = [
    {
      key: ForecastFormulaFrequency.Monthly,
      label: formatMessage({
        id: 'cashflow.side-panel.forecast.edit.formula.frequency.every-month',
      }),
    },
    {
      key: ForecastFormulaFrequency.BiMonthly,
      label: formatMessage({
        id: 'cashflow.side-panel.forecast.edit.formula.frequency.every-2-months',
      }),
    },
    {
      key: ForecastFormulaFrequency.Quarterly,
      label: formatMessage({
        id: 'cashflow.side-panel.forecast.edit.formula.frequency.every-3-months',
      }),
    },
    {
      key: ForecastFormulaFrequency.SemiAnnually,
      label: formatMessage({
        id: 'cashflow.side-panel.forecast.edit.formula.frequency.every-6-months',
      }),
    },
    {
      key: ForecastFormulaFrequency.Annually,
      label: formatMessage({
        id: 'cashflow.side-panel.forecast.edit.formula.frequency.every-12-months',
      }),
    },
  ];

  return (
    <fieldset className={styles.container}>
      <div className={styles.inputs}>
        <DatePicker
          className={styles.datePicker}
          data-testid="forecast-end-date"
          label={formatMessage({ id: 'cashflow.side-panel.forecast.edit.formula.end-date' })}
          maxValue={maxDate}
          minValue={minDate}
          onChange={onEndDateChange}
          value={endDate}
        />
        <ForecastEntryEditVariance
          className={styles.variance}
          currencyCode={currencyCode}
          onChangeType={handleVarianceTypeChange}
          onChangeValue={handleVarianceValueChange}
          type={varianceType}
          value={variance}
        />
      </div>
      <Select
        data-testid="forecast-frequency"
        defaultSelectedKey={ForecastFormulaFrequency.Monthly}
        label={formatMessage({ id: 'cashflow.side-panel.forecast.edit.formula.frequency.title' })}
        onSelectionChange={onFrequencyChange}
        placeholder=""
        selectedKey={frequency}
      >
        {frequencyOptions.map(option => (
          <SelectOption id={option.key} key={option.key}>
            {option.label}
          </SelectOption>
        ))}
      </Select>
    </fieldset>
  );
}
