import type { ReactNode } from 'react';
import { Toggle, Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useFirstTimeExperience } from '../../hooks/use-first-time-experience';
import styles from './projected-forecast-toggle.strict-module.css';

interface ProjectedForecastToggleProps extends React.HTMLAttributes<HTMLDivElement> {
  showProjectedForecast: boolean;
  onShowProjectedForecastChange: (checked: boolean) => void;
}

export function ProjectedForecastToggle({
  showProjectedForecast,
  onShowProjectedForecastChange,
  ...props
}: ProjectedForecastToggleProps): ReactNode {
  const { isFirstTimeExperience, hasFirstTimeExperieceForecastBeenSet } = useFirstTimeExperience();

  const { formatMessage } = useIntl();

  const tooltipLabel = hasFirstTimeExperieceForecastBeenSet
    ? formatMessage({ id: 'cash-flow.first-time-experience.header-filters.tooltip.free-trial' })
    : formatMessage({ id: 'cash-flow.first-time-experience.tooltip.free-trial' });

  return (
    <section className={styles['projected-forecast-toggle']} {...props}>
      <span className="title-4" data-testid="projected-forecast-toggle-title">
        {formatMessage({ id: 'cash-flow.header.projected.toggle' })}
      </span>
      <Tooltip
        isDisabled={!isFirstTimeExperience}
        label={tooltipLabel}
        needsButton
        tooltipTestId="projected-forecast-toggle-tooltip"
      >
        <Toggle
          checked={showProjectedForecast}
          className={cx(isFirstTimeExperience && styles['disabled-toggle'])}
          data-test-projected-forecast-toggle=""
          isDisabled={isFirstTimeExperience}
          onChange={onShowProjectedForecastChange}
        />
      </Tooltip>
    </section>
  );
}
