import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';
import type {
  SubscriptionChangeDataContext,
  TargetSubscription,
  PriceBreakdown,
} from '../../data-context.type';
import { ConfirmDisclaimers } from '../confirm-disclaimers';

interface ConfirmUpgradeProps {
  targetSubscription?: TargetSubscription;
  context: SubscriptionChangeDataContext;
  hasInitialTrial?: boolean;
  planName?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  isBlocked: boolean;
  nextBillingDate: string;
  hasProductChangeNotAvailableError: boolean;
}

export function ConfirmUpgrade({
  context,
  hasInitialTrial,
  targetSubscription,
  planName,
  isLoading,
  onConfirm,
  isBlocked,
  nextBillingDate,
  hasProductChangeNotAvailableError,
}: ConfirmUpgradeProps): ReactElement {
  const { formatMessage, formatNumber } = useIntl();
  const [products, setProducts] = useState<PriceBreakdown[]>([]);

  const isDownsizing = context.recurrence === 'monthly';
  const initialTrialSubtitle =
    hasInitialTrial && 'subscription.change.confirmation.subscription.initial-trial.subtitle';
  const { extraPrice, estimatedPrice, hasInsufficientFunds } = context;
  const isCtaDisabled = hasInsufficientFunds || isBlocked;

  const planCopy = formatMessage(
    {
      id: isDownsizing
        ? 'subscription.change.confirmation.subscription.downsizing.title'
        : 'subscription.change.confirmation.subscription.upsizing.title',
    },
    { planName }
  );
  const planPrice = formatNumber(Number(targetSubscription?.prorated_amount.value), {
    style: 'currency',
    currency: targetSubscription?.prorated_amount.currency,
  });

  useEffect(() => {
    const productsArr: PriceBreakdown[] = [];
    if (targetSubscription) {
      productsArr.push({
        title: planCopy,
        price: planPrice,
        testId: 'data-test-price-plan-breakdown',
      });
    }
    if (extraPrice.extra_virtual_cards_count) {
      productsArr.push({
        title: formatMessage(
          { id: 'subscription.change.confirmation.extra-fees.virtual-card' },
          {
            count: extraPrice.extra_virtual_cards_count,
          }
        ),
        price: formatNumber(Number(extraPrice.extra_virtual_cards_switch_amount.value), {
          style: 'currency',
          currency: extraPrice.extra_virtual_cards_switch_amount.currency,
        }),
        testId: 'data-test-extra-fees-virtual-card',
      });
    }
    if (extraPrice.extra_physical_cards_count) {
      productsArr.push({
        title: formatMessage(
          { id: 'subscription.change.confirmation.extra-fees.physical-card' },
          {
            count: extraPrice.extra_physical_cards_count,
          }
        ),
        price: formatNumber(Number(extraPrice.extra_physical_cards_switch_amount.value), {
          style: 'currency',
          currency: extraPrice.extra_physical_cards_switch_amount.currency,
        }),
        testId: 'data-test-extra-fees-physical-card',
      });
    }
    if (extraPrice.extra_additional_users_count) {
      productsArr.push({
        title: formatMessage(
          { id: 'subscription.change.confirmation.extra-fees.members' },
          {
            count: extraPrice.extra_additional_users_count,
          }
        ),
        price: formatNumber(Number(extraPrice.extra_additional_users_switch_amount.value), {
          style: 'currency',
          currency: extraPrice.extra_additional_users_switch_amount.currency,
        }),
        testId: 'data-test-extra-fees-member',
      });
    }
    setProducts(productsArr);
  }, [targetSubscription, formatNumber, planCopy, planPrice, extraPrice, formatMessage]);

  return (
    <ConfirmLayout
      header={formatMessage({ id: 'subscription.change.confirmation.new.title' })}
      testId="data-test-subscription-change-confirm"
    >
      <>
        <ConfirmHeader
          pricePlan={
            targetSubscription
              ? {
                  title: planCopy,
                  price: planPrice,
                  subtitle: formatMessage(
                    {
                      id:
                        initialTrialSubtitle ||
                        (isDownsizing
                          ? 'subscription.change.confirmation.subscription.downsizing.subtitle'
                          : 'subscription.change.confirmation.subscription.upsizing.subtitle'),
                    },
                    {
                      amount: formatNumber(Number(targetSubscription.product_price.value), {
                        style: 'currency',
                        currency: targetSubscription.product_price.currency,
                      }),
                    }
                  ),
                }
              : undefined
          }
        />
        <ConfirmBillingSummary
          billing={formatMessage(
            { id: 'subscription.change.confirmation.summary.upsizing.subtitle' },
            { date: nextBillingDate }
          )}
          confirmButtonTestId="data-test-confirm-cta"
          confirmText={formatMessage({ id: 'subscription.change.confirmation.cta' })}
          isCtaDisabled={isCtaDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(estimatedPrice.vat_included_amount.value), {
            style: 'currency',
            currency: estimatedPrice.vat_included_amount.currency,
          })}
          products={products}
          testId="data-test-subscription-change-confirm-total"
          total={
            estimatedPrice.type === 'charge'
              ? formatMessage({ id: 'subscription.change.confirmation.charge' })
              : formatMessage({ id: 'subscription.change.confirmation.refund' })
          }
          totalEstimate={estimatedPrice}
        >
          <ConfirmDisclaimers
            context={context}
            hasProductChangeNotAvailableError={hasProductChangeNotAvailableError}
            withMargin={false}
          />
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}
