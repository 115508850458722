import cx from 'clsx';
import type { ReactNode } from 'react';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { FormattedMessage } from '@repo/domain-kit/shared';
import { IconArrowLeftOutlined } from '@repo/monochrome-icons';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { ApplyCta } from 'qonto/react/financing/components/partners/offer-details/apply-cta';
import styles from './compact-header.strict-module.css';

interface PartnerData {
  logo: {
    url: string;
    alt: string;
  };
  name: string;
}

interface CompactHeaderProps {
  partnerData: PartnerData;
  financingType?: string;
  applyLink?: string;
  canRequestFinancing: boolean;
  showHeader: boolean;
}

export function CompactHeader({
  partnerData,
  financingType,
  applyLink,
  canRequestFinancing,
  showHeader,
  ...props
}: CompactHeaderProps): ReactNode {
  const { organization } = useOrganizationManager();

  return (
    <div className={cx(styles.header, showHeader && styles.visible)} {...props}>
      <div className={styles.colLeft}>
        <Link
          className="btn btn--tertiary"
          data-test-link-back
          to={`/organizations/${organization.slug}/financing`}
        >
          <IconArrowLeftOutlined aria-hidden="true" className="mr-8" />
          <FormattedMessage id="financing.details.back" />
        </Link>
      </div>

      <div className={styles.colCenter}>
        <img
          alt={partnerData.logo.alt}
          className={styles.logo}
          data-test-partner-logo
          height="24"
          src={partnerData.logo.url}
          width="24"
        />

        <h3 className="title-3" data-test-partner-name>
          {partnerData.name}
        </h3>
      </div>

      {applyLink && financingType ? (
        <div className={styles.colRight} data-test-apply-cta>
          <ApplyCta
            canRequestFinancing={canRequestFinancing}
            financingType={financingType}
            link={applyLink}
            partnerName={partnerData.name}
          />
        </div>
      ) : null}
    </div>
  );
}
