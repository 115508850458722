import { type ReactNode } from 'react';
import { CardLevel } from '@repo/cards-kit/constants';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ChooseCard } from 'qonto/react/cards/components/flows/choose-card';
import type { Feature } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';
import type { FlashCardLimits } from 'qonto/react/cards/types/limits';
import type { CardSelectorCoreProps } from './card-selector-core-props.interface';
import { useFlashCardUpsellAndTrialProps } from './use-flash-card-upsell-trial-props';

export interface FlashCardUpsellProps {
  hasInitialTrialUpsell: boolean;
  hasInitialTrial: boolean;
  activeTrialRemainingDays: number;
  pricePlan: {
    code: string;
  };
  onUpsell?: (data: { recurrence: string; addonCode: string }) => void;
}

interface FlashCardProps extends CardSelectorCoreProps {
  cardLimit: FlashCardLimits;
  flashCardUpsellProps: FlashCardUpsellProps | undefined;
}

export function FlashCard({
  assetProps,
  cardLimit,
  ctaProps,
  headerProps,
  flashCardUpsellProps,
  layoutProps,
}: FlashCardProps): ReactNode {
  const abilities = useEmberService('abilities');

  const { formatMessage, formatNumber } = useIntl();

  const { ctaProps: innerCtaProps, badge } = useFlashCardUpsellAndTrialProps({
    ctaProps,
    flashCardUpsellProps,
  });

  const features = [
    formatMessage(
      {
        id: 'cards.steps.choose-card.virtual-category.flash.features.limit',
      },
      {
        amount: formatNumber(cardLimit.payment_lifespan_limit_maximum, {
          minimumFractionDigits: 0,
          currency: 'EUR',
          style: 'currency',
        }),
      }
    ),
    abilities.cannot('see x pay card')
      ? formatMessage({
          id: 'cards.steps.choose-card.virtual-category.flash.features.online-payments',
        })
      : formatMessage({ id: 'cards.steps.choose-card.virtual-category.flash.features.x-pay' }),
    formatMessage({ id: 'cards.steps.choose-card.virtual-category.flash.features.validity' }),
  ] as Feature[];

  return (
    <ChooseCard
      assetProps={assetProps}
      cardLevel={CardLevel.Flash}
      ctaProps={innerCtaProps}
      features={features}
      headerProps={{
        ...headerProps,
        badge,
        subtitle: formatMessage({
          id: 'cards.steps.choose-card.virtual-category.flash.subtitle',
        }),
      }}
      layoutProps={layoutProps}
    />
  );
}
