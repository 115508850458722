import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import type { BaseWidgetInvoicesProps } from '../widget-invoices';
import styles from '../styles.strict-module.css';
import { WidgetInvoicesCtaBtn } from './cta-btn';

interface WidgetInvoicesEmptyProps extends ComponentPropsWithoutRef<'div'> {
  invoiceType: BaseWidgetInvoicesProps['invoiceType'];
  invoicingActivationStatus: BaseWidgetInvoicesProps['invoicingActivationStatus'];
  organizationSlug: string;
}

export function WidgetInvoicesEmpty({
  invoiceType,
  invoicingActivationStatus,
  organizationSlug,
  ...htmlAttributes
}: WidgetInvoicesEmptyProps): ReactNode {
  return (
    <div
      className={styles.empty}
      {...{ [`data-test-widget-invoices-${invoiceType}`]: true }}
      {...htmlAttributes}
    >
      <div className={styles['empty-header']}>
        <StaticThemedAsset
          alt=""
          assetPath={
            invoiceType === 'receivable'
              ? '/illustrations/ill-es-qf-ar-create-invoice-web.svg'
              : '/illustrations/ill-es-qf-ap-import-invoice-web.svg'
          }
          className={styles['empty-state']}
          data-test-savings-empty-state-illustration
        />
      </div>
      <div className={styles['empty-content']}>
        <h3 className={styles['empty-title']} data-test-title>
          {invoicingActivationStatus === 'preactivated' ? (
            <FormattedMessage
              id={
                invoiceType === 'receivable'
                  ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.activation.title'
                  : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.activation.title'
              }
            />
          ) : (
            <FormattedMessage
              id={
                invoiceType === 'receivable'
                  ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.no-upcoming-payments.title'
                  : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.no-upcoming-payments.title'
              }
            />
          )}
        </h3>
        <div className={styles['empty-description']} data-test-description>
          {invoicingActivationStatus === 'preactivated' ? (
            <FormattedMessage
              id={
                invoiceType === 'receivable'
                  ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.activation.subtitle'
                  : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.activation.subtitle'
              }
            />
          ) : (
            <FormattedMessage
              id={
                invoiceType === 'receivable'
                  ? 'qonto-invoicing.overview.widgets.client-invoice.empty-state.no-upcoming-payments.subtitle'
                  : 'qonto-invoicing.overview.widgets.supplier-invoice.empty-state.no-upcoming-payments.subtitle'
              }
            />
          )}
        </div>
        <WidgetInvoicesCtaBtn
          invoiceType={invoiceType}
          invoicingActivationStatus={invoicingActivationStatus}
          organizationSlug={organizationSlug}
        />
      </div>
    </div>
  );
}
