import { type ReactNode } from 'react';
import styles from './marketing-testimonial.strict-module.css';

interface MarketingTestimonialProps {
  name: string;
  job: string;
  quote: string;
  picturePath: string;
}

export function MarketingTestimonial({
  name,
  job,
  quote,
  picturePath,
}: MarketingTestimonialProps): ReactNode {
  const alt = `${name}, ${job}`;
  return (
    <div className={styles.marketingTestimonial} data-test-marketing-testimonial-card>
      <p className="body-2 mb-16">{quote}</p>
      <div className={styles.footer}>
        <img alt={alt} src={picturePath} />
        <div>
          <p className="caption-bold">{name}</p>
          <span className="caption">{job}</span>
        </div>
      </div>
    </div>
  );
}
