/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useEffect, useRef, useMemo, useCallback, memo, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSearchTransactions } from 'qonto/react/hooks/use-search-transactions';
import { Pagination } from 'qonto/react/components/transactions/table/pagination';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { BulkSelectionProvider } from 'qonto/react/contexts/bulk-selection-context';
import type { SearchTransactionsMeta, Transaction, TransactionsSearch } from 'qonto/react/graphql';
import { RouteContextProvider } from 'qonto/react/contexts/route-context';
import type ModalsService from 'ember-promise-modals/services';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { QPageSelectorPlaceholder } from 'qonto/react/components/q-page-selector/placeholder';
import { DEFAULT_SORT_PARAM } from 'qonto/react/constants';
import type { DisplayColumn } from 'qonto/constants/table-view';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { DataTable } from '../../table-v2/data-table';
import { generateColumns } from './columns';
import styles from './styles.strict-module.css';
import { BulkSidePanel } from './bulk-side-panel';
import { TransactionTablePlaceholder } from './placeholder/placeholder';

interface TransactionsTableProps {
  bankAccountsQPValue?: string;
  organizationId: string;
  page: number;
  perPage: number;
  searchQuery: string;
  filterGroup: TransactionsSearch['filterGroup'];
  updatePage: (page: number) => void;
  updatePerPage: (perPage: number) => void;
  updateMeta: (meta: SearchTransactionsMeta) => void;
  highlightItem: (id: string) => void;
  selectedItemIds: string[];
  selectItem: (id: string) => void;
  selectAll: () => void;
  openModal: ModalsService['open'];
  didLoadItems?: (items: { transactions: Transaction[]; meta: SearchTransactionsMeta }) => void;
  sortBy?: string;
  handleSortBy?: (sortBy: string | undefined) => void;
  viewColumns: DisplayColumn[] | null;
  updateColumn: (column: Partial<DisplayColumn>) => void;
}

export const TransactionsTable = memo(function TransactionsTable({
  bankAccountsQPValue,
  filterGroup,
  organizationId,
  page,
  perPage,
  updatePage,
  updatePerPage,
  searchQuery = '',
  updateMeta,
  highlightItem,
  selectedItemIds = [],
  selectItem,
  selectAll,
  openModal,
  didLoadItems,
  handleSortBy,
  sortBy,
  viewColumns,
  updateColumn,
}: TransactionsTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/empty-state/search-no-result.json');
  const pinnedColumnElementsRef = useRef<HTMLTableRowElement[]>([]);

  const querySort = sortBy || DEFAULT_SORT_PARAM;
  const [sortProperty, sortDirection] = querySort.split(':');

  const pagination = { page, perPage };
  const abilities = useEmberService('abilities');
  const disableCategoriesQuery = abilities.cannot('view cashflow categories category');

  const query: TransactionsSearch = {
    search: searchQuery,
    sort: {
      property: sortProperty,
      direction: sortDirection as 'asc' | 'desc',
    },
    ...(bankAccountsQPValue ? { bankAccountIds: bankAccountsQPValue.split(',') } : {}),
    filterGroup,
  };
  const {
    data: labelLists,
    isLoading: isLabelListsLoading,
    isError: isLabelListsError,
  } = useFetchLabelLists(organizationId);
  const { isPending: isLoadingCashflowCategories } =
    useFetchCashflowCategories(disableCategoriesQuery);

  const { data, isLoading, isError } = useSearchTransactions(updateMeta, {
    query,
    pagination,
    refetchOnWindowFocus: false,
  });

  const memoizedColumns = useMemo(() => {
    if (!data || !labelLists || !viewColumns) return undefined;

    return generateColumns(labelLists, formatMessage, viewColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formatMessage is stable
  }, [data, labelLists, viewColumns]);

  /**
   * This is a hack to make sure the data is synced between the react component and the ember controller
   * @see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#ondatachanged
   */
  useEffect(() => {
    if (data && didLoadItems) {
      didLoadItems({ transactions: data.transactions, meta: data.meta });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only data is meaningful
  }, [data]);

  useEffect(() => {
    const handleSidebarClosed = (): void => {
      const row = document.getElementsByClassName('row-highlight')[0];
      if (row) row.classList.remove('row-highlight');
    };

    window.addEventListener('sidebar:close', handleSidebarClosed);

    return () => {
      window.removeEventListener('sidebar:close', handleSidebarClosed);
    };
  }, []);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>): void => {
    const target = event.target as HTMLDivElement;
    const isScrolled = target.scrollLeft > 0;

    if (!pinnedColumnElementsRef.current.length) {
      pinnedColumnElementsRef.current = Array.from(document.querySelectorAll('.pinned-column'));
    }

    if (isScrolled) {
      requestAnimationFrame(() => {
        pinnedColumnElementsRef.current.forEach(el => {
          el.classList.add('pinned-column-shadow');
        });
      });
    } else {
      requestAnimationFrame(() => {
        pinnedColumnElementsRef.current.forEach(el => {
          el.classList.remove('pinned-column-shadow');
        });
      });
    }
  }, []);

  if (!viewColumns || isLoading || isLabelListsLoading || isLoadingCashflowCategories) {
    return (
      <div className={styles['loading-wrapper']}>
        <table className={styles['table-loading']} data-testid="transactions-table-loading">
          <tbody>
            <TransactionTablePlaceholder isTableHeader />
            {Array.from({ length: 8 }, (_, index) => (
              <TransactionTablePlaceholder key={index} />
            ))}
          </tbody>
        </table>
        <div className={styles['empty-loading']} />
        <div className={styles['pagination-loading']}>
          <QPageSelectorPlaceholder />
        </div>
      </div>
    );
  }

  if (isError || isLabelListsError) {
    return <div data-testid="transactions-table-error">Error fetching data</div>;
  }

  if (data && labelLists && memoizedColumns) {
    const showEmptyState = !data.transactions.length;

    return (
      <RouteContextProvider routeContext={{ openModal, highlightItem }}>
        <BulkSelectionProvider
          selectAll={selectAll}
          selectItem={selectItem}
          selectedItemIds={selectedItemIds}
        >
          <div className={styles.main}>
            {showEmptyState ? (
              <EmptyStatesInform
                data-testid="transactions-empty-state"
                lottieSrc={lottieSrc}
                subtitle={formatMessage({ id: 'empty-states.system.inform.i2.subtitle' })}
                title={formatMessage({ id: 'empty-states.system.inform.i2.title' })}
              />
            ) : (
              <div
                className={styles.container}
                data-testid="transactions-table"
                id="transactions-table-scroll-container"
                onScroll={handleScroll}
              >
                <DataTable
                  columns={memoizedColumns.columns}
                  data={data.transactions}
                  defaultColumnOrder={memoizedColumns.defaultColumnOrder}
                  handleSortBy={handleSortBy}
                  sortBy={sortBy}
                  updateColumn={updateColumn}
                />
              </div>
            )}
            {(data.meta.totalCount > data.meta.perPage || data.meta.totalCount > 25) && (
              <Pagination
                responseMeta={data.meta}
                updatePage={updatePage}
                updatePerPage={updatePerPage}
              />
            )}
            <BulkSidePanel labelLists={labelLists} organizationId={organizationId} />
          </div>
        </BulkSelectionProvider>
      </RouteContextProvider>
    );
  }

  return null;
}, arePropsEqual);

function arePropsEqual(
  prevProps: TransactionsTableProps,
  nextProps: TransactionsTableProps
): boolean {
  return (
    prevProps.page === nextProps.page &&
    prevProps.perPage === nextProps.perPage &&
    prevProps.searchQuery === nextProps.searchQuery &&
    prevProps.filterGroup === nextProps.filterGroup &&
    prevProps.bankAccountsQPValue === nextProps.bankAccountsQPValue &&
    prevProps.sortBy === nextProps.sortBy &&
    prevProps.viewColumns === nextProps.viewColumns &&
    prevProps.selectedItemIds.length === nextProps.selectedItemIds.length &&
    prevProps.selectedItemIds.every(id => nextProps.selectedItemIds.includes(id))
  );
}
