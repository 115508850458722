/* eslint-disable formatjs/no-literal-string-in-jsx -- Please check and fix */
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Disclaimer } from '@repo/design-system-kit';
import { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@qonto/react-migration-toolkit/react/components';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { DetailedFeeItem } from '../detailed-fee-item';
import styles from './styles.strict-module.css';

interface Fee {
  code: string;
  limitNumber: number;
  consumedNumber: number;
  extraItemUnit: string;
  extraItemAmount?: {
    value: string | null;
    currency: string;
  };
  extraItemAmountPercent: number | null;
  totalAmount: {
    value: string;
    currency: string;
  };
}

interface DetailedFee {
  code: string;
  iconPath: string;
  currency: string;
  title: string;
  subtitle: string;
  totalFee: string;
}

interface DetailedFeesProps {
  additionalFees: Fee[];
  hasPlanChange: boolean;
}

const ICON_MAP = {
  sepa_transfers_and_sdds: '/icon/settings/subscription/consumption-transfer.svg',
  swift_incomes: '/icon/settings/subscription/consumption-transfer.svg',
  fx_transfers: '/icon/settings/subscription/consumption-transfer.svg',
  one_cards: '/icon/settings/subscription/consumption-physical-card.svg',
  one_cards_atm: '/icon/settings/subscription/consumption-atm.svg',
  plus_cards: '/icon/settings/subscription/consumption-physical-card.svg',
  plus_cards_atm: '/icon/settings/subscription/consumption-atm.svg',
  metal_cards: '/icon/settings/subscription/consumption-physical-card.svg',
  virtual_cards: '/icon/settings/subscription/consumption-virtual-card.svg',
  checks: '/icon/settings/subscription/consumption-check.svg',
  memberships: '/icon/settings/subscription/consumption-membership.svg',
  direct_debit_collection_send:
    '/icon/settings/subscription/consumption-incoming-direct-debits.svg',
  other_fees: '/icon/settings/subscription/consumption-fee.svg',
  card_acquirer_payout: '/icon/settings/subscription/consumption-fee.svg',
};

export function DetailedFees({ additionalFees, hasPlanChange }: DetailedFeesProps): ReactNode {
  const { formatMessage, formatNumber } = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');
  const { organization } = useOrganizationManager();
  const sentry = useEmberService('sentry');

  const getSubtitleFor = (fee: Fee): { title: string; subtitle: string } => {
    const {
      code,
      extraItemAmount,
      extraItemUnit,
      extraItemAmountPercent: rate,
      consumedNumber,
      limitNumber,
    } = fee;

    const options = {
      count: consumedNumber,
      limitIncluded: limitNumber,
      price: extraItemAmount?.value
        ? formatNumber(Number(extraItemAmount.value), {
            currency: extraItemAmount.currency,
            style: 'currency',
          })
        : null,
      countLessThanLimit: consumedNumber < limitNumber,
      rate,
    };

    const conditions = {
      hasIncludedChecks: limitNumber > 0,
      isAmountAndPercentUnit: extraItemUnit === 'amount_and_percent',
    };

    if (code === 'virtual_cards') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.addon-overview.details-fees.features.virtual_cards.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.addon-overview.details-fees.features.virtual_cards.subtitle',
          },
          { total: options.count, price: options.price }
        ),
      };
    }

    if (code === 'checks') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.title',
        }),
        subtitle: formatMessage(
          {
            id: conditions.hasIncludedChecks
              ? 'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.other-plans'
              : 'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.basic-plan',
          },
          options
        ),
      };
    }

    if (code === 'fx_transfers') {
      const title = formatMessage({
        id: 'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.title',
      });

      if (conditions.isAmountAndPercentUnit) {
        return {
          title,
          subtitle: formatMessage(
            {
              id: 'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-fees',
            },
            { ...options, fees: options.price }
          ),
        };
      }
      return {
        title,
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-generic',
          },
          options
        ),
      };
    }

    if (code === 'other_fees') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.other_fees.title',
        }),
        subtitle: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.other_fees.subtitle',
        }),
      };
    }

    if (code === 'direct_debit_collection_send') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'sepa_transfers_and_sdds') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'swift_incomes') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'one_cards') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.one_cards.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.one_cards.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'plus_cards') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'metal_cards') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'one_cards_atm') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'plus_cards_atm') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'memberships') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.memberships.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.memberships.subtitle',
          },
          options
        ),
      };
    }

    if (code === 'card_acquirer_payout') {
      return {
        title: formatMessage({
          id: 'subscription.consumption-table.plan-overview.details-fees.features.card-acquirer-payout.title',
        }),
        subtitle: formatMessage(
          {
            id: 'subscription.consumption-table.plan-overview.details-fees.features.card-acquirer-payout.subtitle',
          },
          { payments: options.count }
        ),
      };
    }

    return {
      title: '',
      subtitle: '',
    };
  };

  const detailedFeesList: DetailedFee[] = additionalFees
    .map(fee => {
      const { code, totalAmount } = fee;
      const iconPath = ICON_MAP[code as keyof typeof ICON_MAP];
      const { title, subtitle } = getSubtitleFor(fee);
      if (!title || !subtitle) {
        sentry.captureException(
          new Error(`additional fee ${code} is not supported in table consumption`)
        );
        return null;
      }
      return {
        code,
        iconPath,
        currency: totalAmount.currency,
        title,
        totalFee: totalAmount.value,
        subtitle,
      };
    })
    .filter((fee): fee is DetailedFee => fee !== null);

  return (
    <>
      <h2 className="title-3 mb-8" data-test-detailed-fees-title>
        <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.title" />
      </h2>

      <p className="body-2 mb-24" data-test-detailed-fees-subtitle>
        <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.subtitle" />
        <Link
          className={styles['transactions-list']}
          data-test-detailed-fees-link
          to={`/organizations/${organization.slug}/transactions?filter=fees-subscriptions`}
        >
          <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.link" />
        </Link>
      </p>

      {hasPlanChange ? (
        <Disclaimer.Block className="mb-24" data-test-detailed-fees-disclaimer="" level="info">
          <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.additional-fees.disclaimer" />
        </Disclaimer.Block>
      ) : null}

      <div className="mb-24">
        {detailedFeesList.map(fee => (
          <DetailedFeeItem
            currency={fee.currency}
            data-test-detailed-fee-item={fee.code}
            iconPath={fee.iconPath}
            key={fee.code}
            subtitle={fee.subtitle}
            title={fee.title}
            totalFee={Number(fee.totalFee)}
          />
        ))}
      </div>

      <span className={styles['help-text']} data-test-detailed-fees-help-text>
        <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.last-question" />
        <a
          className="body-link"
          data-test-detailed-fees-help-link
          href={zendeskLocalization.getLocalizedArticle(5898299)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage id="subscription.consumption-table.plan-overview.details-fees.last-question-cta" />
        </a>
        .
      </span>
    </>
  );
}
