import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { TrackRender } from 'qonto/react/components/track-render';
import { EARLY_REPAYMENT_ERRORS } from 'qonto/constants/financing';
import styles from './error-disclaimer.strict-module.css';

interface ErrorDisclaimerProps {
  error: string;
  onTopUpClick?: () => void;
}

/**
 * A component that displays an error message for {@link EARLY_REPAYMENT_ERRORS} in a disclaimer block.
 */
export function ErrorDisclaimer({ error, onTopUpClick }: ErrorDisclaimerProps): ReactElement {
  const { featureBooleanPayLaterRepayByCard } = useFlags();
  let message;

  switch (error) {
    case EARLY_REPAYMENT_ERRORS.INSUFFICIENT_BALANCE_FOR_TOP_UP:
      if (featureBooleanPayLaterRepayByCard) {
        message = (
          <>
            <TrackRender eventName="pay-later-cockpit_repay-by-card_displayed" />
            <FormattedMessage
              id="financing.pay-later.early-repayment.summary.disclaimer.insufficient-funds-topup-enabled.text"
              values={{
                link: (
                  <button
                    className={styles.topupLink}
                    data-test-pay-later-topup-link
                    onClick={onTopUpClick}
                    type="button"
                  >
                    <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.insufficient-funds-topup-enabled.link-text" />
                  </button>
                ),
              }}
            />
          </>
        );
      } else {
        message = (
          <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.insufficient-funds" />
        );
      }
      break;
    case EARLY_REPAYMENT_ERRORS.INSUFFICIENT_BALANCE:
      message = (
        <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.insufficient-funds" />
      );
      break;
    case EARLY_REPAYMENT_ERRORS.DEBIT_BLOCKED:
      message = (
        <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.debit-blocked" />
      );
      break;
    default:
      break;
  }

  return (
    <Disclaimer.Block className={styles.disclaimer} level="error">
      {message}
    </Disclaimer.Block>
  );
}
