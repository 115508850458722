import { useRef, useLayoutEffect, useMemo, type ReactNode } from 'react';
import React from 'react';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { CashFlowSidePanelProvider } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import {
  prepareCashflowTimeseriesRequest,
  useCashflowTimeseries,
} from 'qonto/react/hooks/use-cashflow-timeseries';
import { CashflowPeriodRate, type CashflowPeriod } from 'qonto/react/models/cash-flow-period';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { CASHFLOW_FORECASTED_MONTHS_DEFAULT } from 'qonto/react/api/models/cash-flow-timeseries';
import { useFetchCashflowCategories } from 'qonto/react/hooks/use-fetch-cashflow-categories';
import { useCashflowFlashForecastPreviews } from 'qonto/react/hooks/use-cashflow-forecast-previews';
import type { LabelStatisticsResponse, UnlabeledStatisticsResponse } from '../../api/labels';
import { BalanceTable } from '../balance-table';
import { CategoriesTable } from '../categories-table/categories-table';
import { ChartOverlay } from '../chart-overlay';
import { useTimeseriesBalances } from '../../hooks/use-timeseries-balances';
import { useCategoriesCashflows } from '../../hooks/use-categories-cashflow';
import { CashFlowSidePanel } from '../sidepanel/cash-flow-sidepanel';
import type { LabelTableInterval } from '../../models/labels-cashflow-display';
import styles from './styles.strict-module.css';

interface TablesProps {
  organizationId: string;
  bankAccounts: string;
  selectedPeriod: CashflowPeriod;
  selectedFrequency?: CashflowPeriodRate;
  labelStatistics: LabelStatisticsResponse;
  unlabeledStatistics: UnlabeledStatisticsResponse;
  offset?: number;
  displayedMonths?: number;
  isError?: boolean;
  onNextMonth?: () => void;
  isLastPeriod?: boolean;
  onPreviousMonth?: () => void;
  onSelectInterval?: (interval: LabelTableInterval | undefined) => void;
  isFirstPeriod?: boolean;
  isFirstChartPeriod?: boolean;
  currentDateIndex?: number;
  onRefreshChart: () => void;
  showProjectedForecast: boolean;
}

export function TablesLayout({
  bankAccounts,
  selectedPeriod,
  selectedFrequency,
  offset = 0,
  displayedMonths = 6,
  onNextMonth,
  isLastPeriod = false,
  onPreviousMonth,
  onSelectInterval,
  isFirstPeriod = false,
  currentDateIndex = -1,
  isFirstChartPeriod,
  onRefreshChart,
  showProjectedForecast,
}: TablesProps): ReactNode {
  const targetCellRef = useRef<{
    rowIndex: number;
    columnIndex: number;
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>;
    enterEditMode: boolean;
  } | null>(null);

  useFetchCashflowCategories();
  const { featureBooleanCashFlowFirstTimeExperience } = useFlags();

  const { organization } = useOrganizationManager();
  const abilities = useEmberService('abilities');
  const canEditForecast = abilities.can('edit forecast cash-flow');

  const isForecastEditingEnabled =
    canEditForecast && selectedFrequency === CashflowPeriodRate.Monthly;

  const inflowsTbodyRef = useRef<HTMLTableSectionElement>(null);
  const outflowsTbodyRef = useRef<HTMLTableSectionElement>(null);

  const focusCell = (
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>,
    rowIndex: number,
    columnIndex: number,
    enterEditMode: boolean,
    verticalDirection: 'up' | 'down' | 'same'
  ): void => {
    const tbody = targetTbodyRef.current;
    if (!tbody) return;

    const rows = Array.from(tbody.children) as HTMLTableRowElement[];

    let filteredRows: HTMLTableRowElement[];

    if (verticalDirection === 'down') {
      filteredRows = rows.slice(rowIndex);
    } else {
      filteredRows = rows;
    }

    const row = enterEditMode
      ? filteredRows.find(r => r.getAttribute('data-is-editable') === 'true')
      : rows[rowIndex];

    if (!row) return;

    let cell = row.children[columnIndex] as HTMLElement | undefined;
    if (cell === undefined) {
      cell = row.children[columnIndex - 1] as HTMLElement;
    }
    const button = cell.querySelector('button');
    if (!button) return;
    (button as HTMLElement).focus({ preventScroll: true });
    if (enterEditMode) {
      const editModeButton = button.parentElement?.querySelector('[data-edit-mode-button]');
      if (editModeButton) {
        (editModeButton as HTMLElement).click();
      }
    }
  };

  // Handle cell navigation
  const handleCellNavigation = ({
    rowIndex,
    columnIndex,
    direction,
    targetTbodyRef,
    enterEditMode = false,
  }: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>;
    enterEditMode: boolean;
  }): void => {
    switch (direction) {
      case 'down':
        focusCell(targetTbodyRef, rowIndex + 1, columnIndex, enterEditMode, 'down');
        break;
      case 'up':
        if (rowIndex > 0) focusCell(targetTbodyRef, rowIndex - 1, columnIndex, enterEditMode, 'up');
        break;
      case 'left': {
        const selectedCellIndex = columnIndex - 1;
        if (columnIndex === 1 && !isFirstPeriod) {
          onPreviousMonth?.();
          targetCellRef.current = { rowIndex, columnIndex: 1, targetTbodyRef, enterEditMode };
          return;
        }
        if (selectedCellIndex > 0) {
          focusCell(targetTbodyRef, rowIndex, selectedCellIndex, enterEditMode, 'same');
        }
        break;
      }
      case 'right': {
        const selectedCellIndex = columnIndex + 1;
        if (columnIndex >= displayedMonths && !isLastPeriod) {
          onNextMonth?.();
          targetCellRef.current = {
            rowIndex,
            columnIndex: displayedMonths,
            targetTbodyRef,
            enterEditMode,
          };
          return;
        }
        if (selectedCellIndex <= displayedMonths) {
          focusCell(targetTbodyRef, rowIndex, selectedCellIndex, enterEditMode, 'same');
        }
        break;
      }
    }
  };

  const { queryKey, request } = prepareCashflowTimeseriesRequest({
    selectedFrequency,
    selectedPeriod,
    forecastedPeriods: CASHFLOW_FORECASTED_MONTHS_DEFAULT,
    organization,
    bankAccounts,
  });
  const { data: timeseries, isPending: isLoadingTimeseries } = useCashflowTimeseries(
    request,
    queryKey
  );

  const { inflows, inflowSums, outflows, outflowSums, bufferData } = useCategoriesCashflows(
    offset,
    displayedMonths,
    timeseries
  );

  const { data: flashForecastPreviewData, isPending: isLoadingFlashForecasts } =
    useCashflowFlashForecastPreviews();

  const isLoadingCategoriesTable = isLoadingTimeseries || isLoadingFlashForecasts;

  const onForecastEntryUpdate = (): void => {
    onRefreshChart();
  };

  useLayoutEffect(() => {
    if (targetCellRef.current) {
      const { targetTbodyRef, rowIndex, columnIndex, enterEditMode } = targetCellRef.current;
      focusCell(targetTbodyRef, rowIndex, columnIndex, enterEditMode, 'same');
      targetCellRef.current = null;
    }
  }, [timeseries, inflows, outflows]);

  const { startBalances, endBalances } = useTimeseriesBalances(offset, displayedMonths, timeseries);

  const selectedIndex = currentDateIndex >= 0 ? currentDateIndex : -1;

  const periodNavigation = {
    onNextMonth,
    onPreviousMonth,
    isFirstPeriod: isFirstChartPeriod,
    isLastPeriod,
    selectedPeriod,
    startDate: timeseries?.timeframe.inclusiveStartDate || '',
    endDate: timeseries?.timeframe.exclusiveEndDate || '',
  };

  const showChartOverlay =
    !isLoadingTimeseries &&
    featureBooleanCashFlowFirstTimeExperience &&
    canEditForecast &&
    !abilities.can('fully interact with forecast cash-flow');

  // Separate the tables and sidepanel to prevent unnecessary re-renders
  const TablesContent = useMemo(
    () => {
      return (
        <section className={styles.wrapper} data-testid="tables-layout" key="tables-layout">
          {showChartOverlay ? (
            <div className={styles.chartOverlay}>
              <ChartOverlay numberOfColumns={displayedMonths + 1} />
            </div>
          ) : null}
          <BalanceTable
            currentDateIndex={selectedIndex}
            data={startBalances}
            data-testid="start-balance-table"
            frequency={selectedFrequency}
            isLoading={isLoadingTimeseries}
            key="start-balance-table"
            numberOfColumns={displayedMonths}
            type="start"
          />
          <CategoriesTable
            bankAccounts={bankAccounts}
            currentDateIndex={selectedIndex}
            data={inflows}
            data-testid="inflows-table"
            flashForecastPreviewData={flashForecastPreviewData}
            headerLabelKey="cash-flow-categories.group-title.inflows"
            isForecastEditingEnabled={isForecastEditingEnabled}
            isLoading={isLoadingCategoriesTable}
            key={`inflows-${selectedFrequency}-${selectedIndex}`}
            numberOfColumns={displayedMonths}
            onCellNavigation={({ rowIndex, columnIndex, direction, enterEditMode }) => {
              handleCellNavigation({
                rowIndex,
                columnIndex,
                direction,
                targetTbodyRef: inflowsTbodyRef,
                enterEditMode,
              });
            }}
            onForecastEntryUpdate={onForecastEntryUpdate}
            onRefreshChart={onRefreshChart}
            showProjectedForecast={showProjectedForecast}
            sums={inflowSums}
            tbodyRef={inflowsTbodyRef}
          />
          <CategoriesTable
            bankAccounts={bankAccounts}
            currentDateIndex={selectedIndex}
            data={outflows}
            data-testid="outflows-table"
            flashForecastPreviewData={flashForecastPreviewData}
            headerLabelKey="cash-flow-categories.group-title.outflows"
            isForecastEditingEnabled={isForecastEditingEnabled}
            isLoading={isLoadingCategoriesTable}
            key={`outflows-${selectedFrequency}-${selectedIndex}`}
            numberOfColumns={displayedMonths}
            onCellNavigation={({ rowIndex, columnIndex, direction, enterEditMode }) => {
              handleCellNavigation({
                rowIndex,
                columnIndex,
                direction,
                targetTbodyRef: outflowsTbodyRef,
                enterEditMode,
              });
            }}
            onForecastEntryUpdate={onForecastEntryUpdate}
            onRefreshChart={onRefreshChart}
            showProjectedForecast={showProjectedForecast}
            sums={outflowSums}
            tbodyRef={outflowsTbodyRef}
          />

          <BalanceTable
            currentDateIndex={selectedIndex}
            data={endBalances}
            data-testid="end-balance-table"
            frequency={selectedFrequency}
            isLoading={isLoadingTimeseries}
            key="end-balance-table"
            numberOfColumns={displayedMonths}
            type="end"
          />
        </section>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Deliberately exclude deps to prevent re-renders
    [
      showChartOverlay,
      displayedMonths,
      selectedIndex,
      startBalances,
      selectedFrequency,
      isLoadingTimeseries,
      bankAccounts,
      inflows,
      outflows,
      flashForecastPreviewData,
      isForecastEditingEnabled,
      isLoadingCategoriesTable,
      onRefreshChart,
      showProjectedForecast,
      inflowSums,
      outflowSums,
      endBalances,
    ]
  );

  return (
    <CashFlowSidePanelProvider
      bankAccounts={bankAccounts}
      categoriesData={{
        ...bufferData,
      }}
      onRefreshChart={onRefreshChart}
      onSelectInterval={onSelectInterval}
      periodNavigation={periodNavigation}
      selectedFrequency={selectedFrequency}
    >
      <>
        {TablesContent}
        <CashFlowSidePanel />
      </>
    </CashFlowSidePanelProvider>
  );
}
