import cx from 'clsx';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { Button } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './signature-failure.strict-module.css';

interface SignatureFailureProps {
  backToStep: (step: string) => void;
}

export function SignatureFailure({ backToStep }: SignatureFailureProps): ReactNode {
  const segment = useSegment();

  const handleBackToWarning = (): void => {
    segment.track('pay-later_try-again_clicked', { origin: 'yousign_failure' });
    backToStep('signature-warning');
  };

  return (
    <div className={styles.failure}>
      <StaticThemedAsset
        alt=""
        assetPath="/illustrations/ill-cp-general-external-error.svg"
        className={cx(styles.failureIllustration, 'mb-32')}
        data-test-failure-illustration
      />
      <h1 className="title-2 mb-16 align-center" data-test-failure-title>
        <FormattedMessage id="financing.pay-later.application-flow.failure.title" />
      </h1>
      <p className="body-1 mb-32 align-center" data-test-failure-description>
        <FormattedMessage id="transfers.new.sepa.pay-later.signature-failure.description" />
      </p>
      <Button data-test-failure-cta onPress={handleBackToWarning}>
        <FormattedMessage id="financing.pay-later.application-flow.failure.primary-cta" />
      </Button>
    </div>
  );
}
